import React, { useEffect, useState, useCallback } from 'react';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import Header from '../Layout/Header';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Blog from './Blog';
import TopListing from './TopListing';
import Footer from '../Layout/Footer';
import Help from './Help';

const $ = window.jQuery;
export default function Index() {
    const [cityData, setCityData] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const Navigate = useNavigate();
    useEffect(() => {
        getCity();
    }, []);

  

    const getCity = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetHomeCity);
            if (resp && resp.status === true) {
                setCityData(resp?.response);
                setImageUrl(resp?.image_url);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };

    const handleOnSearchCity = async (stirng) => {
        try {
            let resp = await FetchApi(Endpoints.SearchCity + "/" + stirng);
            if (resp && resp.status === true) {
                let newData = resp?.response.map((item) => {
                    return (
                        {
                            id: item.id,
                            Serchtext: stirng,
                            name: item.name,
                            slug: item.slug,
                            image: item.img === null ? "images/no_image.png" : Endpoints.imagebaseUrl + "/assets/img/cities/" + item.img,
                        }
                    )
                })
				//console.log(newData);
                setCityOption(newData);
                setImageUrl(resp?.image_url);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    const handleOnSelectCity = (item) => {
        setSelectedCity(item[0]?.name);
    };
    const handleOnSearchCategory = async (stirng) => {
        try {
            let resp = await FetchApi(Endpoints.SearchCategory + "/" + stirng);
            if (resp && resp.status === true) {
                let newData = resp?.response.map((item) => {
                    return (
                        {
                            id: item.id,
                            Serchtext: stirng,
                            name: item.name,
                            slug: item.slug,
                            image: item.img === null ? "images/no_image.png" : Endpoints.imagebaseUrl + "/assets/img/category/" + item.img,
                        }
                    )
                })
                setCategoryOption(newData);

            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    const handleOnSelectCategory = (item) => {
        setSelectedCategory(item[0]?.slug);
    }

    const RenderCity = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example-city"
                defaultInputValue={''}
                key={""}
                onSearch={handleOnSearchCity}
                onChange={handleOnSelectCity}
                options={cityOption}
                placeholder="Search City"
                labelKey="name"
                minLength={1}
                emptyLabel={"Search City"}
                className={"form-control sv-input-field"}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id} className="d-flex">
                        <img src={option.image} alt="images" className="rounded-circle city-image-search" />
                        &nbsp;&nbsp; {option.name}
                        {/* <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter> */}
                    </div>

                )}

                useCache={false}
            />
        )
    }, [cityOption])
    const RenderCategory = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example-city"
                defaultInputValue={''}
                key={""}
                onSearch={handleOnSearchCategory}
                onChange={handleOnSelectCategory}
                options={categoryOption}
                placeholder="Search Category"
                labelKey="name"
                minLength={1}
                emptyLabel={"Search City"}
                className={"form-control sv-input-field"}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id} className="d-flex">
                        <img src={option.image} alt="images" className="rounded-circle city-image-search" />
                        &nbsp;&nbsp;{option.name}

                        {/* <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter> */}
                    </div>

                )}

                useCache={false}
            />
        )
    }, [categoryOption])

    const SearchResult = () => {
        if (selectedCity && selectedCategory) {
            Navigate("/local-businesses/" + selectedCity + "/" + selectedCategory);
        } else if (selectedCity) {
            Navigate("/local-businesses/" + selectedCity);
        }

    };
    return (
        <>
            <Header />

            <div className="sv-banner d-none d-md-block">
                <span className="sv-shade"></span>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-sm-12 col-md-12">
                            <div className="sv-banner_title">
                                <h3>Behind every business listing there is an experience that matters</h3>

                            </div>

                            <form className="sv-themeform sv-headerform">
                                <fieldset className="ml-5 px-5">
                                    <div className="sv-themeform__wrap">

                                        <div className="sv-inputhasicon">
                                            <i className="icon-search"></i>
                                            {RenderCity()}
                                            {/* <input type="text" placeholder="What are you looking for?"
                                                className="form-control sv-input-field" required="required" /> */}
                                        </div>
                                        <div className="sv-inputhasicon">
                                            <i className="icon-book"></i>
                                            {RenderCategory()}
                                        </div>
                                        <a href="#" className="sv-btnblack-sm" onClick={() => {
                                            SearchResult();
                                        }}>Search now</a>
                                    </div>
                                    <div className="sv-formcloseicon"><a href="#"><i className="icon-x"></i></a></div>
                                </fieldset>
                            </form>
                            <div className="sv-banner_title">

                                <h4>Trust us we have found the best for you!</h4>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12 col-sm-12 container ml-5">

                            <ul className="sv-explore-categories" style={{ marginLeft: "100px" }}>
                                {cityData.map((item, index) => {
                                    if (index > 10) return null;
                                    return (
                                        <li>
                                            <Link to={"/local-businesses/" + item.name}>
                                                <div className="sv-explore-content">
                                                    <figure>
                                                        {item?.img === null ?
                                                            <img src="images/no_image.png" alt="images" className="city-image-home" />
                                                            :
                                                            <img src={Endpoints.imagebaseUrl + "/assets/img/cities/" + item?.img} alt="images" className="city-image-home" />
                                                        }
                                                    </figure>
                                                    <div className="sv-explore-info">
                                                        <h6>{item?.name}</h6>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })}


                                <li>
                                    <div className="sv-explore-content sv-exploremore-two">
                                        <div className="sv-explore-info">
                                            <h5>Explore more city</h5>
                                            <Link to="/find-business" className="sv-pb-sm">Explore all</Link>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div className="sv-banner d-md-none d-sm-block">
                <span className="sv-shade"></span>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-sm-12 col-md-12">
                            <div className="mobile-banner_title">
                                <h3 className="h6">Behind every business listing there is an experience that matters</h3>

                            </div>

                            <form className="sv-headerform" style={{ width: '107%', marginLeft: '0px' }}>
                                <fieldset className="mobile-headerform" style={{ marginLeft: '0px', width: "104%" }}>
                                    <div className="sv-themeform__wrap">

                                        <div className="sv-inputhasicon">
                                            <i className="icon-search"></i>
                                            {RenderCity()}
                                            {/* <input type="text" placeholder="What are you looking for?"
                                                className="form-control sv-input-field" required="required" /> */}
                                        </div>
                                        <div className="sv-inputhasicon">
                                            <i className="icon-book"></i>
                                            {RenderCategory()}
                                        </div>
                                        <a href="#" className="sv-btnblack-sm" style={{ width: '98%' }} onClick={() => {
                                            SearchResult();
                                        }}>Search now</a>
                                    </div>
                                    <div className="sv-formcloseicon"><a href="#"><i className="icon-x"></i></a></div>
                                </fieldset>
                            </form>
                            <div className="sv-banner_title">

                                <h4>Trust us we have found the best for you!</h4>
                            </div>
                        </div>
                        {/* <div className="col-xl-12 col-md-12 col-sm-12  col-7 container "> */}
                        <div className="sv-explore-categories d-flex">
                            <div className="row" >
                                {cityData.map((item, index) => {
                                    if (index > 10) return null;
                                    return (
                                        <div className={"col-md-2 col-sm-6 col-6 mb-3"}>
                                            <Link to={"/local-businesses/" + item.slug}>
                                                <div className={"sv-explore-content mt-4 "}>
                                                    {item.img === null ?

                                                        <img src={"../images/no_image.png"} alt="images" className="mobile-city-image" />
                                                        :
                                                        <img src={Endpoints.imagebaseUrl + "/assets/img/cities/" + item.img} alt="images" className="mobile-city-image" />
                                                    }
                                                    <div className="sv-explore-info">
                                                        <h6>{item.name}</h6>

                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}

                                <div className={"col-md-2 col-sm-6 col-6 mb-3"}>
                                    <div className="sv-explore-content sv-exploremore-two mt-4">
                                        <div className="sv-explore-info">
                                            <h5>Explore more city</h5>
                                            <Link to="/find-business" className="sv-pb-sm">Explore all</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <main className="sv-main">

                <section className="sv-main-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="sv-main-title-holder text-center">
                                    <div className="sv-maintitle">

                                        <h2>Our Selection Process</h2>
                                    </div>
                                    <div className="sv-main-description">
                                        <p>At topfivebestrated.com, we research and evaluate service experts in 100+ industries. With over 30,000 businesses reviewed, we help you find the most qualified professionals for your needs. Trust our evolving research process for reliable endorsements you can rely on.</p>
                                    </div>
                                </div>
                                <ul className="sv-how-work-list">
                                    <li>
                                        <div className="sv-work-list-icon">
                                            <span className="sv-incomeicon sv-bgpurp">
                                                <img src="images/fig-1.webp" alt="" />
                                            </span>
                                            <h6 className="sv-work-number">01</h6>
                                        </div>
                                        <div className="sv-work-list-title">
                                            <h4>Identify</h4>
                                            <div className="sv-main-description">
                                                <p>By leveraging public databases and customer referrals, we compile a comprehensive directory of local businesses.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sv-work-list-icon" style={{ marginTop: "105px" }}>
                                            <span className="sv-incomeicon">
                                                <img src="images/fig-2.webp" alt="" />
                                            </span>
                                            <h6 className="sv-work-number sv-green-gradient">02</h6>
                                        </div>
                                        <div className="sv-work-list-title">
                                            <h4>Analyze</h4>
                                            <div className="sv-main-description">
                                                <p>Using proprietary algorithms, we meticulously analyse the online presence of providers by scouring public records and information. This enables us to determine their reputation and ensure the highest quality standards.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sv-work-list-icon" style={{ marginTop: "50px" }}>
                                            <span className="sv-incomeicon sv-bgred-gray">
                                                <img src="images/fig-3.webp" alt="" />
                                            </span>
                                            <h6 className="sv-work-number sv-blue-gradient">03</h6>
                                        </div>
                                        <div className="sv-work-list-title">
                                            <h4>Verify</h4>
                                            <div className="sv-main-description">
                                                <p>Our dedicated research team verifies business qualifications, including accreditations, certifications, and licences, to ensure their legitimacy and adherence to industry standards.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sv-work-list-icon" style={{ marginTop: "80px" }}>
                                            <span className="sv-incomeicon sv-bgred">
                                                <img src="images/fig-4.webp" alt="" />
                                            </span>
                                            <h6 className="sv-work-number sv-red-gradient">04</h6>
                                        </div>
                                        <div className="sv-work-list-title">
                                            <h4>Screen / Mystery Shop</h4>
                                            <div className="sv-main-description">
                                                <p>We evaluate finalists based on expertise, services ordered and thorough background checks. Sometimes our mystery teams conduct phone evaluations with each company as the final step in our process</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sv-work-list-icon" style={{ marginTop: "80px" }}>
                                            <span className="sv-incomeicon sv-bgred-green">
                                                <img src="images/fig-5.webp" alt="" />
                                            </span>
                                            <h6 className="sv-work-number sv-new-green-gradient">05</h6>
                                        </div>
                                        <div className="sv-work-list-title">
                                            <h4>List Business</h4>
                                            <div className="sv-main-description ">
                                                <p>Once we've conducted a thorough screening process and determined that your business aligns with our specific selection criteria, we will proceed to list your business for a duration of 3 months.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <Help />

                <TopListing />

                <section className=" d-none d-md-block">
                    <div className="sv-sectionappdark">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-12 col-lg-9 col-md-9">
                                    <div className="sv-sectionapptitle">
                                        <div className="sv-maintitle">
                                            <h4 className="text-white">Is your business better than the business we listed?
                                            </h4>
                                        </div>
                                        <div className="sv-main-description">
                                            <p> If yes, submit your business now</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 mb-3 ">
                                    <Link to={"/list-business"} className="sv-pb "> List Business</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               

                <section className="sv-main-section d-md-none d-sm-block" >
                    <div className="sv-sectionappdark" style={{ height: "auto" }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-12 col-lg-9 col-md-9">
                                    <div className="sv-sectionapptitle">
                                        <div className="sv-maintitle">
                                            <h4 className="text-white">Is your business better than the business we listed?
                                            </h4>
                                        </div>
                                        <div className="sv-main-description">
                                            <p> If yes, submit your business now</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 mb-3 ">
                                    <Link to={"/list-business"} className="sv-pb "> List Business</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                    <Blog />


            </main>
            <Footer />
        </>
    )
}
