import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const $ = window.jQuery;
export default function Model({
  counter,
  setCounter,
  onSuccess
}) {

  useEffect(() => {
    if(counter > 0 ){
      setTimeout(() => setCounter(counter - 1), 1000);
    }else{
      onSuccess(true);
      $("#exampleModal").modal("hide");
    }
    
  }, [counter]);
  return (
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">

          <div className="modal-body p-1">
            <img src="../../images/business.jpg" alt="" />

          </div>
          <div className="model-footer">
            <div className='text-end px-2'
            style={{
              fontSize:'18px'
            }}
            >
              Please wait for <span style={{
                fontWeight:800,
                fontSize:'20px'
              }}>{counter} </span>  seconds
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
