import React from 'react'
import { Link } from 'react-router-dom'

export default function CustomeHeader() {
  return (
    <>
    <header class="navbar navbar-expand-lg sv-navbar d-none d-md-block">
        <div class="container-fluid">
            <Link className="navbar-brand mt-1 p-2" to="/">
                <strong><img src="../../images/logo.png" alt="image description" style={{width: "300px"}} /></strong>
            </Link>
            <div class="sv-navbar-right">

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="fa fa-bars"></span>
                </button>
                <nav class="collapse navbar-collapse sv-navbar-collapse" id="navbarNav">
                <ul className="navbar-nav sv-navbar-nav">
                        <li>
                            <Link to="/list-business" className="sv-pb ">List Business </Link>
                        </li>
                        <li className="px-2">
                            <Link to="/find-business">Find Business </Link>
                        </li>
                       
                        <li>
                            <Link to="/faq">FAQ </Link>
                        </li>
                        {/* <li>
                            <Link to="/about">About </Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact </Link>
                        </li> */}
                        <li>
                        <Link to="https://topfivebestrated.in/portal/login" target={"_blank"}>Login</Link>
                        </li>
                        <li>
                            <div id="google_translate_element"></div>
                        </li>

                    </ul>
                </nav>

            </div>
        </div>
    </header>

    <header class="navbar navbar-expand-lg sv-navbar d-md-none d-sm-block" style={{maxHeight: '100px'}}>
        <div class="container-fluid">
        <div className="d-flex justify-content-between w-100">
            <div className="justify-content-start">
                <Link className="navbar-brand mt-3" to="/">
                    <strong><img src="../../images/logo.png" alt="image description" style={{ width: "200px" }} /></strong>
                </Link>
            </div>
            <div className="justify-content-end">
                <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa fa-bars"></span>
                </button>
            </div>


        </div>
            <div class="sv-navbar-right">

                <nav class="collapse navbar-collapse sv-navbar-collapse" id="navbarNav">
                <ul className="navbar-nav sv-navbar-nav">
                        <li>
                            <Link to="/list-business" className="yellow-btn text-white">List Business </Link>
                        </li>
                        <li className="">
                            <Link to="/find-business">Find Business </Link>
                        </li>
                       
                        <li>
                            <Link to="/faq">FAQ </Link>
                        </li>
                        {/* <li>
                            <Link to="/about">About </Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact </Link>
                        </li> */}
                        <li>
                        <Link to="https://topfivebestrated.in/portal/login" target={"_blank"}>Login</Link>
                        </li>
                        <li>
                            <div id="google_translate_element"></div>
                        </li>

                    </ul>
                </nav>

            </div>
        </div>
    </header>

    </>
  )
}

