import React, { useEffect, useState, useCallback } from 'react';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import CustomeHeader from '../Layout/CustomeHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BusinessCard from './BusinessCard';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import Footer from '../Layout/Footer';
import BusinessCategoryCard from './BusinessCategoryCard';

export default function LocalBusiness() {
    const [cityData, setCityData] = useState([]);
    const [cityDataMore, setCityDataMore] = useState([]);
    const [cityBusinessData, setBusinessCityData] = useState([]);
    const [imageUrl, setImageUrl] = useState('');

    const [cityOption, setCityOption] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const param = useParams();
    const Navigate = useNavigate();
    useEffect(() => {
        getCity();
        getCityBusiness();
        setSelectedCity(param?.slug);
        handleOnSearchCity();
    }, [param?.slug]);

    const handleOnSearchCity = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetAllCity);
            if (resp && resp.status === true) {
            
                setCityOption(resp.response);
                setImageUrl(resp?.image_url);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    const handleOnSelectCity = (item) => {
        setSelectedCity(item[0]?.name);
    };
    const handleOnSearchCategory = async (stirng) => {
        try {
            let resp = await FetchApi(Endpoints.SearchCategory + "/" + stirng);
            if (resp && resp.status === true) {
                let newData = resp?.response.map((item) => {
                    return (
                        {
                            id: item.id,
                            Serchtext: stirng,
                            name: item.name,
                            slug: item.slug,
                            image: item.img === null ? "../images/no_image.png" : Endpoints.imagebaseUrl + "/assets/img/category/" + item.img,
                        }
                    )
                })
                setCategoryOption(newData);

            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    const handleOnSelectCategory = (item) => {
        setSelectedCategory(item[0]?.slug);
    }

    const RenderCity = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example-city"
                defaultInputValue={''}
                key={""}
                onSearch={handleOnSearchCity}
                onChange={handleOnSelectCity}
                options={cityOption}
                placeholder="Search City"
                labelKey="name"
                minLength={1}
                emptyLabel={"Search City"}
                className={"input_location font14 fw400 color111"}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id} className="d-flex gap-2">
                        
                        <img src={option.image} alt="images" className="rounded-circle city-image-search" />
                          
                        {" "}
                            {option?.name}
                    </div>

                )}

                useCache={false}
            />
        )
    }, [cityOption])
    const RenderCategory = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example-city"
                defaultInputValue={''}
                key={""}
                onSearch={handleOnSearchCategory}
                onChange={handleOnSelectCategory}
                options={categoryOption}
                placeholder="Search Category"
                labelKey="name"
                minLength={1}
                emptyLabel={"Search City"}
                className={"sv-input-field subcategory-search-input w-100"}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id} className="d-flex gap-2">
                        
                        <img src={option.image} alt="images" className="rounded-circle city-image-search" />
                          
                        {" "}
                            {option?.name}
                        {/* <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter> */}
                    </div>

                )}

                useCache={false}
            />
        )
    }, [categoryOption])

    const SearchResult = () => {
        if (selectedCity && selectedCategory) {
            Navigate("/local-businesses/" + selectedCity + "/" + selectedCategory);
        } else if (selectedCity) {
            Navigate("/local-businesses/" + selectedCity);
        }

    };

    const getCity = async () => {
        try {
            let resp = await FetchApi(Endpoints.LocalBusiness + "/" + param?.slug);
            if (resp && resp.status === true) {
                let lessSubcategoryArray = [];
                let moreSubcategoryArray = [];
                resp?.response.map((item) => {

                    if (item?.subcategory.length > 3) {
                        moreSubcategoryArray = [...moreSubcategoryArray, item];
                    } else {
                        lessSubcategoryArray = [...lessSubcategoryArray, item];
                    }
                });

                setCityData(lessSubcategoryArray);
                setCityDataMore(moreSubcategoryArray);
                setImageUrl(resp?.image_url);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    const getCityBusiness = async () => {
        try {
            let resp = await FetchApi(Endpoints.CityBusiness + "/" + param?.slug);
            if (resp && resp.status === true) {
                setBusinessCityData(resp?.response)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };

    return (
        <>
            <CustomeHeader />
            <main className="sv-main d-none d-md-block">
                <div className="conatiner">
                    <div className="row  mb-30 container mt-20 mx-30"
                    style={{
                        marginLeft:"70px"
                    }}
                    >
                     
                        <div className="gap-3 m-2 col-md-4 col-sm-12">
                            {/* <input type={"text"} value={param?.slug} disabled className="form-control w-100" /> */}
                            {/* {RenderCity()} */}
                            <select onChange={(e)=>{
                                setSelectedCity(e.target.value)
                            }}>
                                <option>Select City</option>
                                {cityOption.map((item, index)=>{
                                    return (
                                        <option selected={selectedCity === item?.slug ? true : false} key={index} value={item?.slug}>{item?.name}</option>
                                    )
                                })}
                                
                            </select>
                        </div>
                        <div className="border-0 mt-2 mx-30 d-flex col-md-4 col-sm-12">
                           {RenderCategory()}
                           <div class="input-group-append" onClick={()=>{
                            SearchResult()
                           }}>
                               <Link class="input-group-text p-2 w-100">
                                <i class=" fa fa-search w-100 h-100" style={{
                                    fontSize: "1.3rem"
                                }} ></i>
                                </Link>
                            </div>
                            
                        </div>

                    </div>
                    <div className=" home_bannerbox mb-50 container mt-30 " >

                        <div className="home_banner_li pointer home_banner_swiper">
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">


                                <div className="carousel-indicators slide_pagination_bullets">
                                    <span className="slide_pagination_bullet active" data-bs-target="#carouselExampleIndicators" aria-current="true" role="button" data-bs-slide-to="0" aria-label="Go to slide 0">
                                    </span>
                                    <span className="slide_pagination_bullet " data-bs-target="#carouselExampleIndicators" role="button" data-bs-slide-to="1" aria-label="Go to slide 1">
                                    </span>
                                    <span className="slide_pagination_bullet " data-bs-target="#carouselExampleIndicators" role="button" data-bs-slide-to="2" aria-label="Go to slide 2">
                                    </span>
                                    <span className="slide_pagination_bullet " data-bs-target="#carouselExampleIndicators" role="button" data-bs-slide-to="3" aria-label="Go to slide 3">
                                    </span>
                                    <span className="slide_pagination_bullet " data-bs-target="#carouselExampleIndicators" role="button"
                                        data-bs-slide-to="4" aria-label="Go to slide 4">
                                    </span>
                                    <span className="slide_pagination_bullet " data-bs-target="#carouselExampleIndicators" role="button" data-bs-slide-to="5" aria-label="Go to slide 5">
                                    </span>
                                </div>

                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_hotels.png?v=1.0"
                                            className="d-block w-100" />

                                    </div>
                                    <div className="carousel-item">
                                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_packersmovers.png?v=1.1"
                                            className="d-block w-100" alt="" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_interiordesigners.png"
                                            className="d-block w-100" alt="" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_CCTV.png"
                                            className="d-block w-100" alt="" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_bills.png"
                                            className="d-block w-100" alt="" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_webflight_23feb.png"
                                            className="d-block w-100" alt="" />
                                    </div>
                                </div>

                                <div className="slide_control control">
                                    <button className="swiper-button swiper-button-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"></button>
                                    <button className="swiper-button swiper-button-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"></button>
                                </div>
                            </div>



                        </div>
                        <a href="#" className="home_banner_li home_banner_li_effect" style={{ backgroundColor: "#0F7DD8" }}>
                            <div className="home_banner_text" style={{ color: "#FFF" }}>Top Best Food And Pizza In Your City</div>
                            {/* <div className="home_banner_subtext" style={{ color: "#b5deff" }}>Get Nearest Restaurant</div> */}
                            <div className="home_banner_explore" style={{ backgroundColor: "rgb(15, 125, 216)" }}>
                                <div className="home_banner_exploretxt" style={{ color: "white" }}>Explore</div>
                                <div className="home_banner_explorearw" style={{ color: "#FFF" }}></div>
                            </div>
                            <span className="image_banner">
                                <img alt="homebanner"
								src={`${process.env.PUBLIC_URL}/images/food.png`}
                                    decoding="async" className="home_banner_img_btm  h-75"
                                />
                            </span>
                        </a>
                        <a href="#" className="home_banner_li home_banner_li_effect" datatype="#FFF"
                            style={{ backgroundColor: "#00ac7d" }}>
                            <div className="home_banner_text" style={{ color: "#FFF" }}>Top Best Doctors In Your City</div>
                            {/* <div className="home_banner_subtext" style={{ color: "#acffcf" }}>Book Now</div> */}
                            <div className="home_banner_explore" style={{ backgroundColor: "#1b8146" }}>
                                <div className="home_banner_exploretxt" style={{ color: "#FFF" }}>Explore</div>
                                <div className="home_banner_explorearw" style={{ color: "#FFF" }}></div>
                            </div><span className="image_banner"><img
                                alt="homebanner" title=""
								src={`${process.env.PUBLIC_URL}/images/doc.png`}

                                className="home_banner_img_btm w-75 h-75"

                                sizes="100vw" /></span>
                        </a>
                        <a href="#" className="home_banner_li home_banner_li_effect" datatype="#FFF"
                            style={{ backgroundColor: "#6769D0" }}>
                            <div className="home_banner_text" style={{ color: "#FFF" }}>Top Best Professional Services In Your City</div>
                            {/* <div className="home_banner_subtext" style={{ color: "#dedfff" }}>Quick Quotes</div> */}
                            <div className="home_banner_explore" style={{ backgroundColor: "rgb(103, 105, 208)" }}>
                                <div className="home_banner_exploretxt" style={{ color: "white" }}>Explore</div>
                                <div className="home_banner_explorearw" style={{ color: "#FFF" }}></div>
                            </div><span className="image_banner"><img
                                alt="homebanner"
								src={`${process.env.PUBLIC_URL}/images/adv.png`}
                                decoding="async" data-nimg="fill" className="home_banner_img_btm"

                                sizes="100vw" />
                            </span>
                        </a>

                        <a href="#" className="home_banner_li home_banner_li_effect" datatype="#FFF"
                            style={{ backgroundColor: "#a100cf" }}>
                            <div className="home_banner_text" style={{ color: "#FFF" }}>Top Best Beauty (Salon) In Your City</div>
                            {/* <div className="home_banner_subtext" style={{ color: "#ccadff" }}>Get Nearest Vendor</div> */}
                            <div className="home_banner_explore" style={{ backgroundColor: "rgb(161, 0, 207)" }}>
                                <div className="home_banner_exploretxt" style={{ color: "white;" }}>Explore</div>
                                <div className="home_banner_explorearw" style={{ color: "#FFF" }}></div>
                            </div><span className="image_banner"><img
                                alt="homebanner"
								src={`${process.env.PUBLIC_URL}/images/sol.png`}
                                className="home_banner_img_btm  h-75"
                            />
                            </span>
                        </a>


                    </div>
                    <div className="container mb-50 ">
                        <div className="row">
                            {cityData.map((item, index) => {
                                return (
                                    <div className="col-md-6 col-sm-12 mb-30">
                                        <div className="border">
                                            <div className="card-body">
                                                <h3>{item?.name}</h3>
                                                <div className="row ">
                                                    {item?.subcategory.map((subItem, SubIndex) => {
                                                        return (
                                                            <div className="col-md-4 col-sm-3" key={SubIndex}>
                                                                <Link to={"/local-businesses/" + param?.slug + "/" + subItem.slug}>
                                                                    <img src={Endpoints.imagebaseUrl + "/assets/img/category/" + subItem.img} alt='' className="w-100 h-75" />
                                                                    <p className="text-center text-dark">{subItem?.name}</p>
                                                                </Link>

                                                            </div>
                                                        )
                                                    })}



                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                  
                    <div className="container mb-50">
                        <div className="row">
                            {cityDataMore.map((item, index) => {
                                return (
                                    <div className="col-md-12 col-sm-12 mb-30" key={index}>
                                        <div className="border">
                                            <div className="pt-1 px-3">
                                                <div className="d-none d-md-block">

                                                    <div className="row ">
                                                        <div className="col-md-12 mt-3">
                                                            <h3 className='text-center'>{item?.name}</h3>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="row colm-category-list">
                                                                {item?.subcategory.map((subItem, subIndex) => {
                                                                    return (
                                                                        <div className="col-md-1 mt-20 mb-30 colm-item-cat" key={subIndex}>
                                                                            <Link className="home_hotkeybox_li imageZoom text_center" title="" to={"/local-businesses/" + param?.slug + "/" + subItem.slug} >
                                                                                <div className="home_hotkeyimg mb-10">
                                                                                    <span className="category_main_box">
                                                                                        <span className="category_sub_box p-2">

                                                                                            <img src={Endpoints.imagebaseUrl + "/assets/img/category/" + subItem.img} alt={subItem?.name}  className="w-100 h-100 p-1"/>

                                                                                        </span>

                                                                                    </span>
                                                                                </div>
                                                                                {subItem?.name === "Gastroenterologists" ? 
                                                                                <div className="font14 color111 fw400" style={{
                                                                                    wordBreak:"break-all"
                                                                                }}>{subItem?.name}</div>
                                                                                : 
                                                                                <div className="font14 color111 fw400" >{subItem?.name}</div>
                                                                                
                                                                                }
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })}




                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className=" d-md-none d-sm-block">

                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <h3>{item?.name}</h3>

                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="row" >
                                                                {item?.subcategory.map((subItem, subIndex) => {
                                                                    return (
                                                                        <div className="col-md-2 mt-30 mb-30 col-sm-4 category-col-md-4" key={subIndex}>
                                                                            <Link className="home_hotkeybox_li imageZoom text_center" title="" to={"/local-businesses/" + param?.slug + "/" + subItem.slug} >
                                                                                <div className="home_hotkeyimg mb-10">
                                                                                    <span className="category_main_box">
                                                                                        <span className="category_sub_box p-2">

                                                                                            <img src={Endpoints.imagebaseUrl + "/assets/img/category/" + subItem.img} alt={subItem?.name} />

                                                                                        </span>

                                                                                    </span>
                                                                                </div>
                                                                                {subItem?.name.length > 15 ? 
                                                                                <div className="font14 color111 fw400" style={{
                                                                                    wordBreak:"break-all"
                                                                                }}>{subItem?.name}</div>
                                                                                : 
                                                                                <div className="font14 color111 fw400" >{subItem?.name}</div>
                                                                                
                                                                                }
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })}




                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                    <div className=" mb-50 mt-5 container">
                        <div className="row">
                          {cityBusinessData.map((item, index) => {
                                return (
                                    <div className="col-md-12" key={index}>
                                        <BusinessCategoryCard {...{
                                            item: item,
                                            allitem: cityBusinessData
                                        }} />
                                    </div>
                                )
                            })} 


                            
                        </div>

                    </div>
                </div>
            </main>

            <main className="sv-main d-md-none d-sm-block">
                <div className="conatiner">
                    <div className="form-serch-block  mb-30 container mt-20 ">
                     
                        <div className="gap-3 m-2 col-md-4 col-sm-12">
                            {/* <input type={"text"} value={param?.slug} disabled className="form-control w-100" /> */}
                            {/* {RenderCity()} */}
                            <select onChange={(e)=>{
                                setSelectedCity(e.target.value)
                            }}>
                                <option>Select City</option>
                                {cityOption.map((item, index)=>{
                                    return (
                                        <option selected={selectedCity === item?.slug ? true : false} key={index} value={item?.slug}>{item?.name}</option>
                                    )
                                })}
                                
                            </select>
                        </div>
                        <div className="border-0 mt-2 d-flex col-md-4 col-sm-12">
                           {RenderCategory()}
                           <div class="input-group-append" onClick={()=>{
                            SearchResult()
                           }}>
                               <Link class="input-group-text p-2 w-100">
                                <i class=" fa fa-search w-100 h-100" style={{
                                    fontSize: "1.3rem"
                                }} ></i>
                                </Link>
                            </div>
                            
                        </div>

                    </div>
                    <div className="container mb-50">
                        <div className="row">
                            {cityDataMore.map((item, index) => {
                                return (
                                    <div className="col-md-12 col-sm-12 mb-30" key={index}>
                                        <div className="border">
                                            <div className="pt-1 px-3">
                                                <div className="d-none d-md-block">

                                                    <div className="row ">
                                                        <div className="col-md-12 mt-3 text-center">
                                                            <h3 className='text-center'>{item?.name}</h3>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="row colm-category-list">
                                                                {item?.subcategory.map((subItem, subIndex) => {
                                                                    return (
                                                                        <div className="col-md-1 mt-20 mb-30 colm-item-cat" key={subIndex}>
                                                                            <Link className="home_hotkeybox_li imageZoom text_center" title="" to={"/local-businesses/" + param?.slug + "/" + subItem.slug} >
                                                                                <div className="home_hotkeyimg mb-10">
                                                                                    <span className="category_main_box">
                                                                                        <span className="category_sub_box p-2">

                                                                                            <img src={Endpoints.imagebaseUrl + "/assets/img/category/" + subItem.img} alt={subItem?.name}  className="w-100 h-100 p-1"/>

                                                                                        </span>

                                                                                    </span>
                                                                                </div>
                                                                                
                                                                                <div className="font14 color111 fw400">{subItem?.name}</div>
                                                                               
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })}




                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className=" d-md-none d-sm-block">

                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <h3>{item?.name}</h3>

                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="row" >
                                                                {item?.subcategory.map((subItem, subIndex) => {
                                                                    return (
                                                                        <div className="col-md-2 mt-30 mb-30 col-sm-4 category-col-md-4" key={subIndex}>
                                                                            <Link className="home_hotkeybox_li imageZoom text_center" title="" to={"/local-businesses/" + param?.slug + "/" + subItem.slug} >
                                                                                <div className="home_hotkeyimg mb-10">
                                                                                    <span className="category_main_box">
                                                                                        <span className="category_sub_box p-2">

                                                                                            <img src={Endpoints.imagebaseUrl + "/assets/img/category/" + subItem.img} alt={subItem?.name} />

                                                                                        </span>

                                                                                    </span>
                                                                                </div>
                                                                               
                                                                                <div className="font14 color111 fw400" >{subItem?.name}</div>
                                                                               
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })}




                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                    <div className="container mb-50">
                        <div className="row">
                            {cityData.map((item, index) => {
                                return (
                                    <div className="col-md-6 col-sm-12 mb-30 ">
                                        <div className="border">
                                            <div className="card-body">
                                                <h3 className="w-100">{item?.name}</h3>
                                                <div className="row ">
                                                    {item?.subcategory.map((subItem, SubIndex) => {
                                                        return (
                                                            <div className="col-md-4 col-sm-3 city-col-md-6" key={SubIndex}>
                                                                <Link to={"/local-businesses/" + param?.slug + "/" + subItem.slug}>
                                                                    <img src={Endpoints.imagebaseUrl + "/assets/img/category/" + subItem.img} alt='' className="w-100 h-75" />
                                                                    <p className="text-center text-dark">{subItem?.name}</p>
                                                                </Link>

                                                            </div>
                                                        )
                                                    })}



                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </div>

                    {cityBusinessData.map((item, index) => {
                                return (
                                    <div className="col-md-12" key={index}>
                                        <BusinessCategoryCard {...{
                                            item: item,
                                            allitem: cityBusinessData
                                        }} />
                                    </div>
                                )
                            })} 

                  
                    </div>
					
            </main>

            <Footer />

        </>
    )
}
