import React, { useState, useEffect } from 'react'
import ReadMore from './ReadMore';
import { Endpoints } from '../API/Endpoints';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import ReactStars from "react-rating-stars-component";
// import Model from './Model';
import CreateReviewModal from './CreateReviewModal';
import EnquiryModal from './EnquiryModal';

import Model from './Model';
const $ = window.jQuery;
export default function BusinessCard({ item, allitem, onSuccess }) {
    const [showNumber, setShowNumber] = useState(false);
    const [counter, setCounter] = useState("");
    const [action, setAction] = useState("");
    const [webiste, setWebiste] = useState("");
    const [phone, setPhone] = useState("");
    const [businessId, setBusinessId] = useState("");
    const [businessData, setBusinessData] = useState("");
    const Navigate = useNavigate();
    const param = useParams();

    const shareUrl = window.location.origin + "/" + param?.slug;
    useEffect(() => {
        // setTimeout(() => {
        $(".business-image-carousel").owlCarousel({
            autoplay: true,
            dots: false,
            loop: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                900: {
                    items: 1
                }
            }
        });
       
        $(".owl-carousel").owlCarousel({
            autoplay: true,
            dots: false,
            loop: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                900: {
                    items: 2
                }
            }
        });
		
        // }, 1000);


    }, []);
  useEffect(() => {
    // This will be executed every time businessData is updated
  }, [businessData]);

    useEffect(()=>{
        if(counter == 0){
            handleShowResponse();
        }
    }, [counter]);

    const handleShowResponse = () => {
        if (action == "number") {
            setShowNumber(true)
        }
        if (action == "website") {
            window.open(webiste, '_blank', 'noopener,noreferrer');
        }
        if (action == "mobile") {
            let url = "https://wa.me/+91" + phone + "?text=hello";
            window.open(url, '_blank', 'noopener,noreferrer');
        }

    };

   const handleSuccess = () => {
        onSuccess(true);
    };
    const handleEnquirySuccess = () => {
        onSuccess(true);
    };
   
	const handleEnquiryForm = (bdata,myModalenquiry) => {
        setBusinessData(bdata);
        //console.log(businessData);
        $(myModalenquiry).modal("show");
    };
    const handleOpenCreateRatingModal = (Id,createReviewModal) => {
        setBusinessId(Id);
        $(createReviewModal).modal("show");
    };

    useEffect(() => {
        if (counter) {
            $("#exampleModal").modal("show");
        }
    }, [counter]);

    return (
        <div class="row justify-content-center mt-3 ">
            <div class="col-md-12">
                <div className="p-3">
                    <div class="wrapper card card-round">
                        <div class="row g-0">
                            <div class="col-lg-3 col-md-3 d-flex align-items-stretch text-white">

                                <div class="info-wrap bg-green w-100  ">
                                    <div className="owl-carousel business-image-carousel w-100" data-aos="fade-up">

                                        {item?.images?.map((item, index) => {
                                            return (
                                                <div className="testimonial-item w-100" key={index}>
													<div className="info-image-block">
														<img src={item.image} alt='' className="business-image" />
													</div>

                                                </div>

                                            )
                                        })}
                                    </div>

                                    <div class="contact-info p-md-5 p-2 ">
                                        <div className="mt-3 d-none d-md-block">
                                            <button className="btn btn-info d-flex btn-round text-center" >
                                                <div class="icon d-flex align-items-center justify-content-center" >
                                                    <img src='../../images/phone.svg' alt='' style={{
                                                        width:"20px"
                                                    }} />
                                                    &nbsp;
                                                    {showNumber ?
                                                        <span className="ml-2"> {item?.phone}</span>
                                                        :
                                                        <Link className="text-white"
                                                            onClick={() => {
                                                                handleEnquiryForm(item,"#myModalenquiry"+item?.id);
                                                            }}
                                                        > Show Number </Link>
                                                    }
                                                </div>

                                            </button>
                                        </div>
                                        <div className="mt-3 d-md-none d-sm-block">
                                            <button className="btn btn-info d-flex btn-round">
                                                <div class="icon d-flex align-items-center justify-content-center" >
                                                   
                                                    <img src='../../images/phone.svg' alt='' style={{
                                                        width:"20px"
                                                    }} />

                                                  
                                                    &nbsp;
                                                    {showNumber ?
                                                        <span className="ml-2"> {item?.phone}</span>
                                                        :
                                                        <Link className="text-white"

                                                           onClick={() => {
                                                                handleEnquiryForm(item,"#myModalenquiry"+item?.id);
                                                            }}
                                                        > Show Number </Link>
                                                    }
                                                </div>

                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-info btn-round" style={{ paddingLeft: "30px" }}>
                                                <a

                                                    onClick={() => {
                                                                handleEnquiryForm(item,"#myModalenquiry"+item?.id);
                                                            }}
                                                >
                                                    <div class="icon d-flex align-items-center justify-content-center ">
                                                            
                                                       
                                                        <img src='../../images/message.svg' alt='' style={{
                                                            width:"20px"
                                                        }} />
                                                        
                                                        &nbsp;
                                                        <span className="ml-2 text-white">  Send Message</span>
                                                    </div>
                                                </a>
                                            </button>


                                        </div>
                                        <div className="mt-1">
                                            <button className="btn btn-info btn-round" >
                                                <a

                                                  onClick={() => {
                                                                handleEnquiryForm(item,"#myModalenquiry"+item?.id);
                                                            }}
                                                >
                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                       
                                                        <img src='../../images/website.svg' alt='' style={{
                                                            width:"18px"
                                                        }} />
                                                        &nbsp;
                                                       
                                                        <span className="ml-2 text-white"

                                                        >Visit Website</span>
                                                    </div>
                                                </a>
                                            </button>
                                        </div>
                                        <div className="mt-1">
                                            <button className="btn btn-info btn-round">
                                                <a

                                                   onClick={() => {
                                                                handleEnquiryForm(item,"#myModalenquiry"+item?.id);
                                                            }}

                                                >
                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                        <div >
                                                         <img src='../../images/location.svg' alt='' style={{
                                                            width:"20px"
                                                        }} />
                                                        </div>
                                                        &nbsp;
                                                        <span className="ml-2 text-white"

                                                        >Get Direction</span>
                                                    </div>
                                                </a>
                                            </button>
                                        </div>

                                    </div>


                                    <div>

                                    </div>


                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 order-md-last d-flex align-items-stretch d-none d-md-block pl-3 gap-2">
                                <div class="contact-wrap w-100 mt-3 ">
                                    <h3 className="px-7">{item?.name}</h3>
                                    <p className="location-view d-flex mb-2 px-7">
                                        <div className="location-icon" style={{
                                            width: "20px",
                                            height: "20px",
                                           
                                        }}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" class="svg-inline--fa fa-location-dot fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"></path></svg>
                                        </div>
                                        &nbsp;
                                        {item?.address}
                                    </p>
									
                                  
									
									  <div className="service_item-list">
										  <div className="service__top">
											    {item?.services?.map((serviceItem, serviceIndex) => (
												  serviceItem && (
													serviceItem.includes(',') ? (
													  serviceItem.split(',').map((theitem, theindex) => (
														<div key={theindex} className="service_item">
														  {theitem.trim()}
														</div>
													  ))
													) : (
													  <div key={serviceIndex} className="service_item">
														{serviceItem.trim()}
													  </div>
													)
												  )
												))}

										  </div>
										


									  


                                    </div>


                                    <div className="flex flex-wrap lg:order-1 lg:grid lg:auto-cols-fr lg:grid-flow-col lg:bg-off-white ">
                                        <div className="flex-grow  border-gray-light px-7 py-6 lg:border-e lg:border-t-0 lg:last:border-e-0">
                                            <div className="mx-auto space-y-2 sm:max-w-[15rem]">
                                                <h3 className="text-lg">Reputation:</h3>

                                                <dl class="Ratings_ratings__6YCFd">
                                                    <dt>
                                                        Google

                                                    </dt>
                                                    <dd>
                                                        <span class="font-semibold">4.9</span> / 5 (414)
                                                    </dd>
                                                    <dt>
                                                        Facebook

                                                    </dt>
                                                    <dd>
                                                        <span class="font-semibold">4.9</span> / 5 (130)
                                                    </dd>
                                                    <dt>
                                                        Top Five best

                                                    </dt>
                                                    <dd>
                                                        <span class="font-semibold">{item?.rating}</span> / 5 ({item?.reviews?.length})
                                                    </dd>
                                                </dl>
                                            </div>
                                            <div class="mt-2 flex flex-wrap items-center justify-center gap-x-6 text-gray">
                                                <button type="button" class="flex items-center gap-2" onClick={() => {
                                                    handleOpenCreateRatingModal(item?.id,"#createReviewModal"+item?.id)
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 22.6" fill="currentColor" class="feeback-icon">
                                                        <path d="M24.6,1,24,.4a1.5,1.5,0,0,0-2,0L12.9,9.5v.2L12,12.3a1.3,1.3,0,0,0,.1.6h.6l2.6-.8h.2L24.6,3A1.5,1.5,0,0,0,24.6,1Zm-1.3.2.5.5c.2.1.2.4,0,.6h0l-.6.6L22.1,1.8l.6-.6c.1-.2.4-.2.6,0ZM13.8,11.6l-.4-.4.3-1.2,1.1,1.3Z">
                                                        </path>
                                                        <path d="M16.8,14.2H3.4v1.3H16.8Z">
                                                        </path>
                                                        <path d="M13.1,7.4H3.4V8.7H12Z">
                                                        </path>
                                                        <path d="M11.1,10.8H3.4v1.3h7.5Z">
                                                        </path>
                                                        <path d="M18.8,17.4a1,1,0,0,1-.9,1H6.1L3.9,20.6V19a.6.6,0,0,0-.6-.6h-1a1,1,0,0,1-1.1-1V5.6a1.1,1.1,0,0,1,1.1-1H16.1l1.2-1.2H2.3A2.2,2.2,0,0,0,0,5.6V17.4a2.3,2.3,0,0,0,2.3,2.3h.3v2.4a.5.5,0,0,0,.3.5h.4l.3-.2,3.2-2.8H17.9a2.2,2.2,0,0,0,2.2-2.2h0V9.2l-1.3,1.3Z"></path>
                                                    </svg>
                                                    <span>Leave Feedback</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex-grow border-gray-light px-7 py-6 lg:border-e lg:border-t-0 lg:last:border-e-0">
                                            <div className="mx-auto space-y-2 sm:max-w-[15rem]">
                                                <h3 className="text-lg">Professionalism:</h3>

                                                <dl class="grid grid-cols-[1fr_auto] gap-x-4">
                                                    {item.graph ? Object.keys(item.graph).length > 0 ?

                                                        Object.keys(item.graph).map((item, index) => {

                                                            if (index > 4) return null;
                                                            return (
                                                                <>
                                                                    <dt style={{
                                                                        fontSize: "15px"
                                                                    }}>{item.split("_").length > 1 ? item.split("_")[0] + " " + item.split("_")[1] : item}</dt>
                                                                    {/* {Object.values(item.graph)} */}
                                                                    <dd>

                                                                        <ReactStars
                                                                            count={5}
                                                                            edit={false}
                                                                            value={5}
                                                                            size={20}
                                                                            isHalf={false}
                                                                            activeColor="#ffd700"
                                                                            activeBackgroundColor="#ffd700"
                                                                        />
                                                                    </dd>
                                                                </>
                                                            )
                                                        })


                                                        : ""
                                                        : ""
                                                    }
                                                </dl>
                                            </div>

                                        </div>

                                        <div className="flex-grow  border-gray-light px-7 py-6 lg:border-e lg:border-t-0 lg:last:border-e-0">
                                            <div className="mx-auto space-y-2 sm:max-w-[15rem]">
                                                <h3 className="text-lg">Price: Request a Quote or Schedule a Free Consultation</h3>

                                            </div>

                                        </div>

                                    </div>


                                    <div className='px-7 description-content'>
                                        <b>Expert Opinion</b>
                                        <p>
                                            {item?.description}
                                        </p>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div></div>
                                        <div className="text-end px-2 px-2 " >
                                            <button className=" border rounded-circle p-2 w-75" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src='../../images/share.svg' alt='' />
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                <li>

                                                    <a class="dropdown-item" href="#">
                                                        <FacebookShareButton
                                                            url={shareUrl}

                                                            className="d-flex gap-2"
                                                        >
                                                            <FacebookIcon size={22} round={true} />
                                                            <h5>
                                                                Facebook
                                                            </h5>

                                                        </FacebookShareButton>

                                                    </a>
                                                </li>
                                                <li><a class="dropdown-item" href="#">
                                                    <LinkedinShareButton
                                                        url={shareUrl}

                                                        className="d-flex gap-2">
                                                        <LinkedinIcon size={22} round={true} />
                                                        <h5>
                                                            Linkedin
                                                        </h5>

                                                    </LinkedinShareButton>
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <TwitterShareButton

                                                        url={shareUrl}
                                                        className="d-flex gap-2">
                                                        <TwitterIcon size={25} round={true} />
                                                        <h5>
                                                            Twitter
                                                        </h5>

                                                    </TwitterShareButton>
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <WhatsappShareButton
                                                        url={shareUrl}

                                                        className="d-flex gap-2">
                                                        <WhatsappIcon size={22} round={true} />
                                                        <h5>
                                                            Whatsapp
                                                        </h5>

                                                    </WhatsappShareButton>
                                                </a></li>
                                            </ul>

                                        </div>
                                    </div>




                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9 order-md-last d-flex align-items-stretch p-4 d-md-none d-sm-block">
                                <div class="contact-wrap w-100 mt-2 ">
                                    <h3 className="">{item?.name}</h3>
                                    <p className="location-view d-flex mb-2 ">
                                        <div className="location-icon" style={{
                                            width: "20px",
                                            height: "20px",
                                           
                                        }}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" class="svg-inline--fa fa-location-dot fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"></path></svg>
                                        </div>
                                        &nbsp;
                                        {item?.address}
                                    </p>
	
								
										
                                   <div className="service_item-list">
                                        <div className="service__top">
											  
												    {item?.services?.map((serviceItem, serviceIndex) => (
												  serviceItem && (
													serviceItem.includes(',') ? (
													  serviceItem.split(',').map((theitem, theindex) => (
														<div key={theindex} className="service_item">
														  {theitem.trim()}
														</div>
													  ))
													) : (
													  <div key={serviceIndex} className="service_item">
														{serviceItem.trim()}
													  </div>
													)
												  )
												))}

											  </div>
										



                                    </div>


                                    <div className="flex flex-wrap lg:order-1 lg:grid lg:auto-cols-fr lg:grid-flow-col lg:bg-off-white ">
                                        <div className="flex-grow  border-gray-light  py-6 lg:border-e lg:border-t-0 lg:last:border-e-0">
                                            <div className="mx-auto space-y-2 sm:max-w-[15rem]">
                                                <h3 className="text-lg">Reputation:</h3>

                                                <dl class="Ratings_ratings__6YCFd">
                                                    <dt>
                                                        Google

                                                    </dt>
                                                    <dd>
                                                        <span class="font-semibold">4.9</span> / 5 (414)
                                                    </dd>
                                                    <dt>
                                                        Facebook

                                                    </dt>
                                                    <dd>
                                                        <span class="font-semibold">4.9</span> / 5 (130)
                                                    </dd>
                                                    <dt>
                                                        Top Five best

                                                    </dt>
                                                    <dd>
                                                        <span class="font-semibold">{item?.rating}</span> / 5 ({item?.reviews?.length})
                                                    </dd>
                                                </dl>
                                            </div>
                                            <div class="mt-2 flex flex-wrap items-center justify-center gap-x-6 text-gray">
                                                <button type="button" class="flex items-center gap-2" onClick={() => {
                                                    handleOpenCreateRatingModal(item?.id,"#createReviewModal"+item?.id);
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 22.6" fill="currentColor" class="feeback-icon">
                                                        <path d="M24.6,1,24,.4a1.5,1.5,0,0,0-2,0L12.9,9.5v.2L12,12.3a1.3,1.3,0,0,0,.1.6h.6l2.6-.8h.2L24.6,3A1.5,1.5,0,0,0,24.6,1Zm-1.3.2.5.5c.2.1.2.4,0,.6h0l-.6.6L22.1,1.8l.6-.6c.1-.2.4-.2.6,0ZM13.8,11.6l-.4-.4.3-1.2,1.1,1.3Z">
                                                        </path>
                                                        <path d="M16.8,14.2H3.4v1.3H16.8Z">
                                                        </path>
                                                        <path d="M13.1,7.4H3.4V8.7H12Z">
                                                        </path>
                                                        <path d="M11.1,10.8H3.4v1.3h7.5Z">
                                                        </path>
                                                        <path d="M18.8,17.4a1,1,0,0,1-.9,1H6.1L3.9,20.6V19a.6.6,0,0,0-.6-.6h-1a1,1,0,0,1-1.1-1V5.6a1.1,1.1,0,0,1,1.1-1H16.1l1.2-1.2H2.3A2.2,2.2,0,0,0,0,5.6V17.4a2.3,2.3,0,0,0,2.3,2.3h.3v2.4a.5.5,0,0,0,.3.5h.4l.3-.2,3.2-2.8H17.9a2.2,2.2,0,0,0,2.2-2.2h0V9.2l-1.3,1.3Z"></path>
                                                    </svg>
                                                    <span>Leave Feedback</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex-grow border-gray-light  py-6 lg:border-e lg:border-t-0 lg:last:border-e-0">
                                            <div className="mx-auto space-y-2 sm:max-w-[15rem]">
                                                <h3 className="text-lg">Professionalism:</h3>

                                                <dl class="grid grid-cols-[1fr_auto] gap-x-4">
                                                    {item.graph ? Object.keys(item.graph).length > 0 ?

                                                        Object.keys(item.graph).map((item, index) => {

                                                            if (index > 4) return null;
                                                            return (
                                                                <>
                                                                    <dt style={{
                                                                        fontSize: "15px"
                                                                    }}>{item.split("_").length > 1 ? item.split("_")[0] + " " + item.split("_")[1] : item}</dt>
                                                                    {/* {Object.values(item.graph)} */}
                                                                    <dd>

                                                                        <ReactStars
                                                                            count={5}
                                                                            edit={false}
                                                                            value={5}
                                                                            size={20}
                                                                            isHalf={false}
                                                                            activeColor="#ffd700"
                                                                            activeBackgroundColor="#ffd700"
                                                                        />
                                                                    </dd>
                                                                </>
                                                            )
                                                        })


                                                        : ""
                                                        : ""
                                                    }
                                                </dl>
                                            </div>

                                        </div>

                                        <div className="flex-grow  border-gray-light  py-6 lg:border-e lg:border-t-0 lg:last:border-e-0">
                                            <div className="mx-auto space-y-2 sm:max-w-[15rem]">
                                                <h3 className="text-lg">Price: Request a Quote or Schedule a Free Consultation</h3>

                                            </div>

                                        </div>

                                    </div>


                                    <div className=''>
                                        <b>Expert Opinion</b>
                                        <p>
                                            {item?.description}
                                        </p>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div></div>
                                        <div className="text-end " >
                                            <button className=" border rounded-circle p-2 w-75" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src='../../images/share.svg' alt='' />
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                <li>

                                                    <a class="dropdown-item" href="#">
                                                        <FacebookShareButton
                                                            url={shareUrl}

                                                            className="d-flex gap-2"
                                                        >
                                                            <FacebookIcon size={22} round={true} />
                                                            <h5>
                                                                Facebook
                                                            </h5>

                                                        </FacebookShareButton>

                                                    </a>
                                                </li>
                                                <li><a class="dropdown-item" href="#">
                                                    <LinkedinShareButton
                                                        url={shareUrl}

                                                        className="d-flex gap-2">
                                                        <LinkedinIcon size={22} round={true} />
                                                        <h5>
                                                            Linkedin
                                                        </h5>

                                                    </LinkedinShareButton>
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <TwitterShareButton

                                                        url={shareUrl}
                                                        className="d-flex gap-2">
                                                        <TwitterIcon size={25} round={true} />
                                                        <h5>
                                                            Twitter
                                                        </h5>

                                                    </TwitterShareButton>
                                                </a></li>
                                                <li><a class="dropdown-item" href="#">
                                                    <WhatsappShareButton
                                                        url={shareUrl}

                                                        className="d-flex gap-2">
                                                        <WhatsappIcon size={22} round={true} />
                                                        <h5>
                                                            Whatsapp
                                                        </h5>

                                                    </WhatsappShareButton>
                                                </a></li>
                                            </ul>

                                        </div>
                                    </div>




                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

            {/* {counter ? */}
                
                <Model {...{
                    counter: counter,
                    setCounter: setCounter,
                    onSuccess: handleShowResponse
                }} />
              {/* : ""}  */}


            <CreateReviewModal
                {...{
                    businessId: businessId,
                    setBusinessId: setBusinessId,
				 createReviewModal: "createReviewModal"+item?.id,

                    onSuccess: handleSuccess
                }}
            />

			   <EnquiryModal
                {...{
                    businessData: businessData,
                    setBusinessData: setBusinessData,
                    allitem: allitem,
                    myModalenquiry: "myModalenquiry"+item?.id,
                    onSuccess: handleEnquirySuccess
                }}
            />

        </div>
    )
}
