import React, { useState } from 'react'

import { Endpoints } from "../API/Endpoints";
import { CommonAPi } from "../API/CommonApi";
import ToastMessage from "../Utils/ToastMessage";

const $ = window.jQuery;
export default function Help() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");

    const handleSave = async(e) =>{
        e.preventDefault();
        let data = {
            name:name,
            email:email,
            phone:phone,
            date:date,
            description:description,
        }
        try {
          
            let resp = await CommonAPi(Endpoints.SheduleCall, data);
            if (resp) {
                ToastMessage.Success(resp.msg);
                setName("");
                setEmail("");
                setPhone("");
                setDate("");
                setDescription("");
                $("#contactModal").modal("hide");

            }else{
                ToastMessage.Error(resp.message); 
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.msg) {
                ToastMessage.Error(e.response.data.msg);
            }
        }
    };
    return (

        <section className="sv-main-section sv-main-section sv-bgred-gray m-0">
            <div className=" text-center  py-3">
                <h2>We Can Help!</h2>
                <p className=''>Whether you need custom recommendations or want to grow your business, our sales team is here to help!</p>
                <div className="row d-none d-md-block">
                    <div className='col-md-12 mt-3 mb-0'>
                        <button
                            class="items-center rounded-lg font-bold leading-none no-underline lg:leading-none gap-4 px-6 py-3  mx-5 text-lg lg:text-xl inline-flex border-2 bg-transparent text-blue hover:border-blue-dark hover:text-blue-dark border-blue "
                            type="button"
                            data-bs-toggle="modal" data-bs-target="#contactModal"
                        >
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5429 2.88308H15.4857V0.567456C15.4857 0.272347 15.2555 0.0330811 14.9715 0.0330811H13.2572C12.9732 0.0330811 12.7429 0.272347 12.7429 0.567456V2.88308H7.25717V0.567456C7.25717 0.272347 7.0269 0.0330811 6.74288 0.0330811H5.0286C4.74458 0.0330811 4.51431 0.272347 4.51431 0.567456V2.88308H2.45717C1.32102 2.88308 0.400024 3.84006 0.400024 5.02058V18.2956C0.400024 19.4761 1.32102 20.4331 2.45717 20.4331H17.5429C18.679 20.4331 19.6 19.4761 19.6 18.2956V5.02058C19.6 3.84006 18.679 2.88308 17.5429 2.88308ZM17.2857 18.2956H2.71431C2.64611 18.2956 2.58071 18.2674 2.53248 18.2173C2.48426 18.1672 2.45717 18.0993 2.45717 18.0284V7.15808H17.5429V18.0284C17.5429 18.0993 17.5158 18.1672 17.4676 18.2173C17.4193 18.2674 17.3539 18.2956 17.2857 18.2956ZM15.0208 10.5604L8.92182 16.8468C8.72018 17.0546 8.39455 17.0533 8.1945 16.8438L4.97631 13.4728C4.7763 13.2633 4.77758 12.9249 4.97922 12.7171L5.9529 11.7135C6.15454 11.5057 6.48017 11.507 6.68018 11.7166L8.57035 13.6965L13.3306 8.79C13.5322 8.58217 13.8579 8.58351 14.0579 8.79303L15.0237 9.80469C15.2238 10.0142 15.2224 10.3526 15.0208 10.5604Z" fill="currentColor"></path></svg>
                            <span class="flex-1 text-center">Schedule A Call</span>
                        </button>

                        <a href="tel:+91-8699490011"
                            class="items-center rounded-lg font-bold leading-none no-underline lg:leading-none gap-4 px-6 py-3 text-lg lg:text-xl inline-flex bg-blue  text-white hover:bg-blue-dark border-blue"
                            data-track="review_call">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.2 16.975H15.4C15.2375 16.975 15.0969 16.9119 14.9781 16.7857C14.8594 16.6596 14.8 16.5101 14.8 16.3375V9.96249C14.8 9.78984 14.8594 9.64042 14.9781 9.51425C15.0969 9.38808 15.2375 9.32499 15.4 9.32499H16C16 7.35937 15.4844 5.80546 14.4531 4.66328C13.4219 3.52109 11.9375 2.94999 9.99999 2.94999C8.06249 2.94999 6.57812 3.52109 5.54687 4.66328C4.51562 5.80546 3.99999 7.35937 3.99999 9.32499H4.59999C4.76249 9.32499 4.90312 9.38808 5.02187 9.51425C5.14062 9.64042 5.19999 9.78984 5.19999 9.96249V16.3375C5.19999 16.5101 5.14062 16.6596 5.02187 16.7857C4.90312 16.9119 4.76249 16.975 4.59999 16.975H3.99999C3.99999 17.6789 4.23437 18.2799 4.70312 18.7779C5.17187 19.276 5.73749 19.525 6.39999 19.525H7.59999C7.59999 19.1664 7.71562 18.8643 7.94687 18.6185C8.17812 18.3728 8.46249 18.25 8.79999 18.25H9.99999C10.3375 18.25 10.6219 18.3728 10.8531 18.6185C11.0844 18.8643 11.2 19.1631 11.2 19.515C11.2 19.867 11.0844 20.1691 10.8531 20.4215C10.6219 20.6738 10.3375 20.8 9.99999 20.8H6.39999C5.39999 20.8 4.54999 20.4281 3.84999 19.6844C3.14999 18.9406 2.79999 18.0375 2.79999 16.975C2.13749 16.975 1.57187 16.6031 1.10312 15.8594C0.634369 15.1156 0.399994 14.2125 0.399994 13.15C0.399994 12.4594 0.509369 11.8219 0.728119 11.2375C0.946869 10.6531 1.23749 10.1949 1.59999 9.86288V9.32499C1.59999 8.1164 1.82187 6.96093 2.26562 5.85859C2.70937 4.75624 3.30624 3.80663 4.05624 3.00976C4.80624 2.21288 5.69999 1.5787 6.73749 1.10722C7.77499 0.635736 8.86249 0.399994 9.99999 0.399994C11.1375 0.399994 12.225 0.635736 13.2625 1.10722C14.3 1.5787 15.1937 2.21288 15.9437 3.00976C16.6937 3.80663 17.2906 4.75624 17.7344 5.85859C18.1781 6.96093 18.4 8.1164 18.4 9.32499V9.86288C18.7625 10.1949 19.0531 10.6531 19.2719 11.2375C19.4906 11.8219 19.6 12.4594 19.6 13.15C19.6 14.2125 19.3656 15.1156 18.8969 15.8594C18.4281 16.6031 17.8625 16.975 17.2 16.975Z" fill="currentColor">
                                </path>
                            </svg>
                            <span class="flex-1 text-center">
                                <span class="hidden lg:inline">(+91) - 8699490011</span><span class="lg:hidden">CALL NOW</span>
                            </span>
                        </a>
                    </div>

                </div>
                <div className="row d-md-none d-sm-block">
                    <div className='col-md-12 mt-3 mb-0'>
                        <button
                            class="items-center rounded-lg font-bold leading-none no-underline lg:leading-none gap-4 px-6 py-3  mx-5 text-lg lg:text-xl inline-flex border-2 bg-transparent text-blue hover:border-blue-dark hover:text-blue-dark border-blue w-75"
                            type="button"
                            data-bs-toggle="modal" data-bs-target="#contactModal"
                        >
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5429 2.88308H15.4857V0.567456C15.4857 0.272347 15.2555 0.0330811 14.9715 0.0330811H13.2572C12.9732 0.0330811 12.7429 0.272347 12.7429 0.567456V2.88308H7.25717V0.567456C7.25717 0.272347 7.0269 0.0330811 6.74288 0.0330811H5.0286C4.74458 0.0330811 4.51431 0.272347 4.51431 0.567456V2.88308H2.45717C1.32102 2.88308 0.400024 3.84006 0.400024 5.02058V18.2956C0.400024 19.4761 1.32102 20.4331 2.45717 20.4331H17.5429C18.679 20.4331 19.6 19.4761 19.6 18.2956V5.02058C19.6 3.84006 18.679 2.88308 17.5429 2.88308ZM17.2857 18.2956H2.71431C2.64611 18.2956 2.58071 18.2674 2.53248 18.2173C2.48426 18.1672 2.45717 18.0993 2.45717 18.0284V7.15808H17.5429V18.0284C17.5429 18.0993 17.5158 18.1672 17.4676 18.2173C17.4193 18.2674 17.3539 18.2956 17.2857 18.2956ZM15.0208 10.5604L8.92182 16.8468C8.72018 17.0546 8.39455 17.0533 8.1945 16.8438L4.97631 13.4728C4.7763 13.2633 4.77758 12.9249 4.97922 12.7171L5.9529 11.7135C6.15454 11.5057 6.48017 11.507 6.68018 11.7166L8.57035 13.6965L13.3306 8.79C13.5322 8.58217 13.8579 8.58351 14.0579 8.79303L15.0237 9.80469C15.2238 10.0142 15.2224 10.3526 15.0208 10.5604Z" fill="currentColor"></path></svg>
                            <span class="flex-1 text-center">Schedule A Call</span>
                        </button>

                        <a href="tel:+91-8699490011"
                            class="items-center rounded-lg font-bold leading-none no-underline lg:leading-none gap-4 px-6 py-3 text-lg lg:text-xl inline-flex bg-blue  text-white hover:bg-blue-dark border-blue mt-3 w-75" 
                            data-track="review_call">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.2 16.975H15.4C15.2375 16.975 15.0969 16.9119 14.9781 16.7857C14.8594 16.6596 14.8 16.5101 14.8 16.3375V9.96249C14.8 9.78984 14.8594 9.64042 14.9781 9.51425C15.0969 9.38808 15.2375 9.32499 15.4 9.32499H16C16 7.35937 15.4844 5.80546 14.4531 4.66328C13.4219 3.52109 11.9375 2.94999 9.99999 2.94999C8.06249 2.94999 6.57812 3.52109 5.54687 4.66328C4.51562 5.80546 3.99999 7.35937 3.99999 9.32499H4.59999C4.76249 9.32499 4.90312 9.38808 5.02187 9.51425C5.14062 9.64042 5.19999 9.78984 5.19999 9.96249V16.3375C5.19999 16.5101 5.14062 16.6596 5.02187 16.7857C4.90312 16.9119 4.76249 16.975 4.59999 16.975H3.99999C3.99999 17.6789 4.23437 18.2799 4.70312 18.7779C5.17187 19.276 5.73749 19.525 6.39999 19.525H7.59999C7.59999 19.1664 7.71562 18.8643 7.94687 18.6185C8.17812 18.3728 8.46249 18.25 8.79999 18.25H9.99999C10.3375 18.25 10.6219 18.3728 10.8531 18.6185C11.0844 18.8643 11.2 19.1631 11.2 19.515C11.2 19.867 11.0844 20.1691 10.8531 20.4215C10.6219 20.6738 10.3375 20.8 9.99999 20.8H6.39999C5.39999 20.8 4.54999 20.4281 3.84999 19.6844C3.14999 18.9406 2.79999 18.0375 2.79999 16.975C2.13749 16.975 1.57187 16.6031 1.10312 15.8594C0.634369 15.1156 0.399994 14.2125 0.399994 13.15C0.399994 12.4594 0.509369 11.8219 0.728119 11.2375C0.946869 10.6531 1.23749 10.1949 1.59999 9.86288V9.32499C1.59999 8.1164 1.82187 6.96093 2.26562 5.85859C2.70937 4.75624 3.30624 3.80663 4.05624 3.00976C4.80624 2.21288 5.69999 1.5787 6.73749 1.10722C7.77499 0.635736 8.86249 0.399994 9.99999 0.399994C11.1375 0.399994 12.225 0.635736 13.2625 1.10722C14.3 1.5787 15.1937 2.21288 15.9437 3.00976C16.6937 3.80663 17.2906 4.75624 17.7344 5.85859C18.1781 6.96093 18.4 8.1164 18.4 9.32499V9.86288C18.7625 10.1949 19.0531 10.6531 19.2719 11.2375C19.4906 11.8219 19.6 12.4594 19.6 13.15C19.6 14.2125 19.3656 15.1156 18.8969 15.8594C18.4281 16.6031 17.8625 16.975 17.2 16.975Z" fill="currentColor">
                                </path>
                            </svg>
                            <span class="flex-1 text-center">
                                <span class="hidden lg:inline">(+91) - 8699490011</span><span class="lg:hidden">CALL NOW</span>
                            </span>
                        </a>
                    </div>

                </div>
            </div>

            <div className="modal fade" id="contactModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="card-header py-3 text-center">
                            Contact Info (Schedule Call)
                        </div>
                        <div className="modal-body p-1">
                            <form onSubmit={handleSave}>
                            <div className="row container">
                                <div className="col-md-12">
                                    <label>Name *</label>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        value={name}
                                        onChange={(e)=>{
                                            setName(e.target.value)
                                        }}
                                        required
                                        />
                                </div>
                                <div className="col-md-12">
                                    <label>Email *</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={email}
                                        onChange={(e)=>{
                                            setEmail(e.target.value)
                                        }}
                                        required 
                                        />
                                </div>
                                <div className="col-md-12">
                                    <label>Phone Number (Enter whatsapp number if you have) *</label>
                                    <input 
                                        type="text" 
                                        value={phone}
                                        onChange={(e)=>{
                                            setPhone(e.target.value)
                                        }}
                                        required
                                        className="form-control" />
                                </div>
                                <div className="col-md-12">
                                    <label>Date *</label>
                                    <input 
                                        type="date" 
                                        value={date}
                                        onChange={(e)=>{
                                            setDate(e.target.value)
                                        }}
                                        required
                                        className="form-control" />
                                </div>
                                <div className="col-md-12">
                                    <label>Description *</label>
                                    <textarea 
                                    onChange={(e)=>{
                                        setDescription(e.target.value)
                                    }}
                                    required
                                    className="form-control">
                                        {description ?  description : ""}
                                    </textarea>
                                </div>
                                <div className="col-md-12 mt-3 text-center mb-3">
                                    <input type="submit" className="sv-pb" />
                                </div>


                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
