import React, { useEffect, useState } from 'react'
import CustomeHeader from '../Layout/CustomeHeader';
import Footer from '../Layout/Footer';

const $ = window.jQuery;
export default function About() {

   
    useEffect(() => {
        $(".testimonials-carousel").owlCarousel({
            autoplay: true,
            dots: true,
            loop: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                900: {
                    items: 3
                }
            }
        });
    }, []);

  
    return (
        <>
            <CustomeHeader />
            <main className="sv-main sv-content sv-slider-section">

                <section id="contact" className="contact">
                    <div className="container">
                        <div className="row">
                            <h2>ABOUT US</h2>
                            <div>
                                <h3>Who We Are</h3>
                            </div>
                            <div className="col-md-6">
                                <p className="about-text">In a country as vast as India with multiple options for everything, it can often get rather
                                    confusing to find the best-rated establishments. From doctors to automobile service centres
                                    and even restaurants, it becomes a monumental task to discover top-rated establishments
                                    which are not sponsored. Looking for the best doctors but regular search engines have failed
                                    you in the past with expectations not meeting reality? Fear not because, Top Five Best rated
                                    is India's first, non-paid and non-sponsored rating website where you can find all the
                                    top-rated establishments with no hassle. Our dedicated team personally analyse every entry
                                    on the list based on 30 parameters to ensure that you get only the best, honest and accurate
                                    reviews.</p>
                          
                                <p className="about-text">We provide our users with the top 5 reviewed institutions of every category in every city.
                                    Our services are not just restricted to tier 1 cities but also extends to tier 2 cities.
                                    With an extensive network, we aim to make travelling easier for all. Our one of a kind
                                    service aims to provide users with an honest and accurate listing which have been personally
                                    verified. The next time you are trying to find a service, Top Five best Rated is the place
                                    to complete your search!</p>
                                    </div>
                            <div className="col-md-6">
                            <img src="../images/topfive-logo.png" alt='' className='w-100 ' />

                            </div>
                        </div>
                        <hr />
                    </div>

                </section>

                <section>
                    <div className="sv-slider-section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="sv-maintitlev2 text-center">
                                        <h2>Top Facts About Best Five Rated- Why Choose Us?</h2>
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gy-5 sv-dbwrapper">
                                        <div className="col-sm-12 col-md-6 col-xl-4">
                                            <div className="sv-dbitemmainwrap">
                                                <div className="sv-dbitemmain">
                                                    <div className="sv-iteminfo text-center">
                                                        <center>
                                                        <span className="sv-incomeicon sv-incomeiconlg">
                                                            <img src="../images/money.png" alt='' className="rounded-pill" />
                                                        </span>
                                                        </center>

                                                    </div>
                                                    <div className="sv-dbitemdetail">

                                                        <h6>
                                                        We make your Search easy.
                                                        </h6>
                                                        <p>
                                                        We're here to simplify your search. We're among the top five best-rated platforms that provide you with the finest options in your city. Our team conducts thorough research and even arranges mystery shopper phone evaluations for  business (if required). This ensures that we deliver the top results in your area, making it easy for our users to find the best services.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-xl-4">
                                            <div className="sv-dbitemmainwrap">
                                                <div className="sv-dbitemmain">
                                                    <div className="sv-iteminfo text-center">
                                                        
                                                        <span className="sv-incomeicon sv-incomeiconlg">
                                                            <img src="../images/city-listing.png" alt='' className="rounded-pill" />
                                                        </span>

                                                    </div>
                                                    <div className="sv-dbitemdetail">

                                                        <h6>
                                                            At Top Five Rated, we offer listings that span cities and towns throughout India.
                                                        </h6>
                                                        <p>
                                                            Our commitment goes beyond major metropolitan areas, as we extend our services to include smaller towns and tier-two cities. Our goal is to provide a comprehensive representation of businesses across the entire nation, ensuring that our user base can fully explore and benefit from the diverse offerings we have to provide. We believe in inclusivity and strive to encompass the entirety of the nation
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-xl-4">
                                            <div className="sv-dbitemmainwrap">
                                                <div className="sv-dbitemmain">
                                                    <div className="sv-iteminfo text-center">
                                                        <span className="sv-incomeicon sv-incomeiconlg">
                                                            <img src="../images/listing-create.png" alt='' className="rounded-pill" />
                                                        </span>

                                                    </div>
                                                    <div className="sv-dbitemdetail">

                                                        <h6>
                                                            We create our listings based on a selection of meticulously chosen parameters.
                                                        </h6>
                                                        <p>
                                                            Our dedicated teams conduct thorough evaluations of each business, considering a comprehensive range of 30 criteria, which encompass factors such as cost-effectiveness and customer satisfaction. These evaluations are carried out with precision before we publish our listings, ensuring that our users consistently experience satisfaction and that we maintain a commitment to fairness and impartiality.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              


                <section id="testimonials" className="testimonials pb-5 mb-5">
                    <div className="container">
                        <hr />
                        <div className="section-title" data-aos="zoom-out">
                            <h2>Testimonials</h2>
                            <p>What Business Owner Say About Us:</p>
                        </div>

                        <div className="owl-carousel testimonials-carousel" data-aos="fade-up">

                            <div className="testimonial-item sv-dbitemmainwrap p-3 mt-5">
                                <p>

                                    It was a pleasure to deal with Top Five Best Rated customer service. They made it easy. I am honored I was also selected to be one of the top 3 attorneys in India.

                                </p>

                                <h3>Nishant Sharma</h3>
                                <h4>Ceo &amp; Founder</h4>
                            </div>

                            <div className="testimonial-item sv-dbitemmainwrap  p-3 mt-5">
                                <p>

                                    Top Five Best Rated are an extremely reliable review based site. They thoroughly do their homework with each business. You can be sure that if you choose one of their premium recommended businesses in any profession you won't be disappointed.
                                </p>

                                <h3>Ankush Sethi</h3>
                                <h4>Designer</h4>
                            </div>

                            <div className="testimonial-item sv-dbitemmainwrap  p-3 mt-5">
                                <p>

                                    Top Five Best Rated reached out to us to let us know we were in the top five. They replied very fast, updated any changes we requested, and offered more optional changes also. Thank you Top Five Best Rated & Team!

                                </p>

                                <h3>Jena Karlis</h3>
                                <h4>Store Owner</h4>
                            </div>

                            <div className="testimonial-item sv-dbitemmainwrap  p-3 mt-5">
                                <p>

                                    Great company. We are pleased to be included in their Top five best rated surgeons in Delhi!
                                </p>

                                <h3>Shilpi Verma</h3>
                                <h4>Freelancer</h4>
                            </div>

                            <div className="testimonial-item sv-dbitemmainwrap  p-3 mt-5">
                                <p>

                                    TopFiveBestRated support is the most responsive team.
                                    They are always ready to help you with any issues, questions you have about their services.

                                </p>

                                <h3>Chris Paul</h3>
                                <h4>Entrepreneur</h4>
                            </div>

                        </div>

                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}
