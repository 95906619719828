import React from 'react'
import CustomeHeader from '../Layout/CustomeHeader'
import Footer from '../Layout/Footer'

export default function Terms() {
    return (
        <>
            <CustomeHeader />
            <div className="container mt-30 mb-30 term">
                <div className="row">
                    <div className="col-md-12">
                        <h2>
                            Terms & Condition
                        </h2>
                    </div>
                    <div className="col-md-9">
                        <h6>User Reviews, Ratings, and Comments:</h6>
                        <ul>
                            <li>
                                Users can post reviews, ratings, and comments about TopFiveBestRated services and advertisers through various mediums
                            </li>
                            <li>
                                Users are responsible for the content they post, and TopFiveBestRated does not necessarily endorse or share their views.
                            </li>
                            <li>
                                By posting, users grant TopFiveBestRated the right to use their content.
                            </li>
                            <li>
                                Users confirm they have the right to grant these rights and waive moral rights.
                            </li>
                            <li>
                                TopFiveBestRated may monitor and edit or remove inappropriate content.
                            </li>
                            <li>
                                Content should not be obscene, false, defamatory, or infringe on any rights.
                            </li>
                            <li>
                                Users may be legally responsible for their posts, and TopFiveBestRated is not liable for user-generated content.
                            </li>
                        </ul>
                        <h6>
                            Warranty Disclaimer:
                        </h6>
                        <ul>
                            <li>
                                TopFiveBestRated does not endorse or guarantee the quality of advertisers
                            </li>
                            <li>
                                Users should verify all aspects before engaging with advertisers.
                            </li>
                            <li>
                                TopFiveBestRated does not investigate or endorse the accuracy, legality, or reliability of products, services, or promotions.
                            </li>
                            <li>
                                TopFiveBestRated is not responsible for the accuracy or legality of information on the platform.
                            </li>
                            <li>
                                Users use the platform at their own risk, and 5BestInCity disclaims any liability for damages or losses.
                            </li>
                        </ul>
                        <h6>
                            Content Disclaimer:
                        </h6>
                        <ul>

                            <li>
                                TopFiveBestRated displays content from various sources, but it has no control over the content's accuracy or quality.
                            </li>
                            <li>
                                Content may contain errors, and TopFiveBestRated is not liable for any resulting loss or damage.
                            </li>
                            <li>
                                Information, prices, and availability are subject to change without notice.
                            </li>
                            <li>
                                TopFiveBestRated reserves the right to make improvements and correct errors.
                            </li>
                            <li>
                                Users may read, print, or download content for personal use only.
                            </li>
                            <li>
                                Unauthorized use of platform content is prohibited.
                            </li>
                        </ul>
                        <h6>
                            Links Disclaimer:
                        </h6>
                        <ul>
                            <li>
                                External links are provided for convenience, but TopFiveBestRated does not endorse or guarantee external sites' performance or authenticity.
                            </li>
                            <li>
                                TopFiveBestRated is not responsible for the content, safety, or legality of external sites.
                            </li>
                            <li>
                                TopFiveBestRated may disable links to third-party sites.
                            </li>
                            <li>
                                Users should exercise discretion when using external links.
                            </li>
                        </ul>
                        <h6
                        >General Disclaimer:
                        </h6>
                        <ul>
                            <li>
                                The platform's information, products, and services are provided "as is" without warranties.
                            </li>
                            <li>
                                TopFiveBestRated disclaims all warranties, including merchantability and fitness for a particular purpose.
                            </li>
                            <li>
                                TopFiveBestRated does not guarantee uninterrupted or error-free services.
                            </li>
                            <li>
                                Users acknowledge that these disclaimers are essential forTopFiveBestRated to provide access to the platform.
                            </li>
                        </ul>
                        <h6>
                            Changes:
                        </h6>
                        <ul>
                            <li>
                                TopFiveBestRated can modify or replace these terms at its discretion.
                            </li>
                            <li>
                                Material revisions will be notified with a 30-day notice.
                            </li>
                            <li>
                                Continued use of the service implies acceptance of the revised terms.
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-3">
                        <img src="../images/topfive-logo.png" alt='' className='w-100' />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
