import React, { useEffect, useState, useRef } from 'react'
import CustomeHeader from '../Layout/CustomeHeader';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import { CommonAPi } from "../API/CommonApi";
import ToastMessage from "../Utils/ToastMessage";
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Layout/Footer';
const $ = window.jQuery;
export default function ListBusiness() {
    const [activeTab, setActiveTab] = useState(1);

    const setponeref = React.useRef();
    const steptworef = React.useRef();
    const stepthreeref = React.useRef();
    const stepfourref = React.useRef();
    const stepfiveref = React.useRef();
    //  Setp 1 State
    const [BusinessName, setBusinessName] = useState("");
    const [BusinessPhone, setBusinessPhone] = useState("");
    const [ServiceProvide, setServiceProvide] = useState("");
    const [BusinessWebsite, setBusinessWebsite] = useState("");
    const [BusinessCity, setBusinessCity] = useState("");
    const [BusinessCityNew, setBusinessCityNew] = useState("");
    const [BusinessState, setBusinessState] = useState("");
    const [BusinessStateNew, setBusinessStateNew] = useState("");
    const [BusinessCategory, setBusinessCategory] = useState("");
    const [BusinessCategoryNew, setBusinessCategoryNew] = useState("");
    const [BusinessSubCategory, setBusinessSubCategory] = useState("");
    const [BusinessSubCategoryNew, setBusinessSubCategoryNew] = useState("");
    const [BusinessFrom, setBusinessFrom] = useState("");
    const [BusinessTo, setBusinessTo] = useState("");
    const [BusinessDay, setBusinessDay] = useState([]);


    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubcategoryData] = useState([]);

    //  Setp 1 Error State
    const [BusinessNameError, setBusinessNameError] = useState("");
    const [BusinessPhoneError, setBusinessPhoneError] = useState("");
    const [ServiceProvideError, setServiceProvideError] = useState("");
    const [BusinessCityError, setBusinessCityError] = useState("");
    const [BusinessStateError, setBusinessStateError] = useState("");
    const [BusinessCategoryError, setBusinessCategoryError] = useState("");
    const [BusinessSubCategoryError, setBusinessSubCategoryError] = useState("");
    const [BusinessFromError, setBusinessFromError] = useState("");
    const [BusinessToError, setBusinessToError] = useState("");
    const [BusinessDayError, setBusinessDayError] = useState("");

    // Step 2 State
    const [Name, setName] = useState("");
    const [relation, setRelation] = useState("");
    const [bussinessAddress, setbussinessAddress] = useState("");
    const [bussinessAddressUrl, setbussinessAddressUrl] = useState("");
    const [bussinessEmail, setbussinessEmail] = useState("");



    // Step 2 Error State
    const [NameError, setNameError] = useState("");
    const [relationError, setRelationError] = useState("");
    const [bussinessAddressError, setbussinessAddressError] = useState("");
    const [bussinessAddressUrlError, setbussinessAddressUrlError] = useState("");
    const [bussinessEmailError, setbussinessEmailError] = useState("");

    //  Step 3 State

    const [objective, setObjective] = useState([]);


    //  Step 4 State
    const [Instagram, setInstagram] = useState("");
    const [Facebook, setFacebook] = useState("");
    const [Linkedin, setLinkedin] = useState("");
    const [Youtube, setYoutube] = useState("");

    const [InstagramError, setInstagramError] = useState("");
    const [FacebookError, setFacebookError] = useState("");
    const [LinkedinError, setLinkedinError] = useState("");
    const [YoutubeError, setYoutubeError] = useState("");

    //  Step 5 State
    const [serviceInput, setServiceInput] = useState([
        ""
    ]);
    const [businessDescription, setBusinessDescription] = useState("");
    const [businessVideo, setBusinessVideo] = useState("");
    const [businessPhoto, setBusinessPhoto] = useState([]);
    const [businessDiscount, setBusinessDiscount] = useState("");
    const [review, setReview] = useState("");

    const [serviceError, setServiceError] = useState("");
    const [businessDescriptionError, setBusinessDescriptionError] = useState("");
    const [businessVideoError, setBusinessVideoError] = useState("");
    const [businessPhotoError, setBusinessPhotoError] = useState("");
    const [businessDiscountError, setBusinessDiscountError] = useState("");
    const [reviewError, setReviewError] = useState("");
    const [status, setStatus] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        getCity();
        getCategory();
    }, []);
    const getCategory = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetCategory);
            if (resp && resp.status === true) {
                setCategoryData(resp?.response);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
	const getCity = async () => {
        try {
            let resp = await FetchApi(Endpoints.FindBusiness);
            if (resp && resp.status === true) {
                setStateData(resp?.response);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };

    const handleChangeState = (index) => {
        const newData = stateData.filter((item, ind) => ind == index);
        if (newData.length > 0) {
            setCityData(newData[0]?.city);
            setBusinessState(newData[0]?.id);
        }else if(index=="other"){
			
			setCityData([]);
			
			setBusinessState(index);
		}
    };
    const handleChangeCategory = (index) => {
        const newData = categoryData.filter((item, ind) => ind == index);
        if (newData.length > 0) {
            setSubcategoryData(newData[0]?.subcategory);
            setBusinessCategory(newData[0]?.id);
        }else if(index=="other"){
			setSubcategoryData([]);
			setBusinessCategory(index);
		}
    };
    const handleChangeCity = async (city) => {
        const newData = cityData.filter((item, ind) => item?.name == city);

        if (newData.length > 0) {
            setBusinessCity(newData[0]?.id);
        }else if(city=="other"){
			setBusinessCity(city);
		}
        
    };

    const handleNext = (tab) => {
        let nextStep = true;

        if (tab == 2) {
            if (!BusinessName) {
                nextStep = false;
                setBusinessNameError("Business name is required");
            } else {
                setBusinessNameError("");
            }
            if (!BusinessPhone) {
                nextStep = false;
                setBusinessPhoneError("Business phone no is required");
            } else {
                setBusinessPhoneError("");
            }
            if (!ServiceProvide) {
                nextStep = false;
                setServiceProvideError("Service provide is required");
            } else {
                setServiceProvideError("");
            }
            if (!BusinessCity) {
                nextStep = false;
                setBusinessCityError("select business city");
            } else if (BusinessCity=="other" && !BusinessCityNew) {
                nextStep = false;
                setBusinessCityError("Enter the New City Name");
            } else {
                setBusinessCityError("");
            }
            if (!BusinessState) {
                nextStep = false;
                setBusinessStateError("Select business state ");
            } else  if (BusinessState=="other" && !BusinessStateNew) {
                nextStep = false;
                setBusinessStateError("Enter the New State Name");
            } else{
                setBusinessStateError("");
            }
            if (!BusinessCategory) {
                nextStep = false;
                setBusinessCategoryError("Select business category");
            }  else  if (BusinessCategory=="other" && !BusinessCategoryNew) {
                nextStep = false;
                setBusinessCategoryError("Enter the New Category Name");
            } else {
                setBusinessCategoryError("");
            }
            if (!BusinessSubCategory) {
                nextStep = false;
                setBusinessSubCategoryError("Select business subcategory");
            }   else  if (BusinessSubCategory=="other" && !BusinessSubCategoryNew) {
                nextStep = false;
                setBusinessSubCategoryError("Enter the New SubCategory Name");
            } else {
                setBusinessSubCategoryError("");
            }
            if (!BusinessFrom) {
                nextStep = false;
                setBusinessFromError("Business from is required");
            } else {
                setBusinessFromError("");
            }
            if (!BusinessTo) {
                nextStep = false;
                setBusinessToError("Business to is required");
            } else {
                setBusinessToError("");
            }
            if (BusinessDay.length === 0) {
                nextStep = false;
                setBusinessDayError("Business day is required");
            } else {
                setBusinessDayError("");
            }
        }
        if (tab == 3) {
            if (!Name) {
                nextStep = false;
                setNameError("Name is required");
            } else {
                setNameError("");
            }
            if (!relation) {
                nextStep = false;
                setRelationError("Select relation");
            } else {
                setRelationError("");
            }
            if (!bussinessAddress) {
                nextStep = false;
                setbussinessAddressError("Bussiness address is required");
            } else {
                setbussinessAddressError("");
            }
            if (!bussinessAddressUrl) {
                nextStep = false;
                setbussinessAddressUrlError("Bussiness address url is required");
            } else {
                setbussinessAddressUrlError("");
            }
            if (!bussinessEmail) {
                nextStep = false;
                setbussinessEmailError("Bussiness email is required");
            } else {
                setbussinessEmailError("");
            }
        }



        if (nextStep == true) {
            setActiveTab(tab)
        }

    };

    const handleCheckBox = (e, day) => {

        if (e.target.checked) {
            if (BusinessDay.filter((item) => item === day).length === 0) {
                setBusinessDay([...BusinessDay, day]);
            }
        } else {
            if (BusinessDay.filter((item) => item === day).length > 0) {
                const newData = BusinessDay.filter((item) => item != day);
                setBusinessDay(newData);
            }
        }
    };

    const handleRemoveInput = (ind) => {
        const newData = serviceInput.filter((item, index) => index != ind);
        setServiceInput(newData);
    };

    const handleRemoveImage = (ind) => {
        const newData = businessPhoto.filter((item, index) => index != ind);
        setBusinessPhoto(newData);
		setBusinessPhotoError("");
    };

    const handleChangeServiceData = (value, index) => {
        serviceInput[index] = value;
    };

   const handleFile = (e) => {
		if (businessPhoto.length < 3) {
			setBusinessPhotoError("");

			const chosenFiles = Array.from(e.target.files);

			// Check file type and size
			const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp'];
			const maxSize = 400 * 1024; // 600 KB

			const validFiles = chosenFiles.filter(file => {
				if (allowedTypes.includes(file.type) && file.size <= maxSize) {
					return true;
				} else {
					// Display error message for invalid file types or sizes
					setBusinessPhotoError("Invalid file type or size. Please upload JPG, PNG, or BMP files up to 400 KB.");
					return false;
				}
			});

			// Update state only with valid files
			setBusinessPhoto(prevPhotos => [...prevPhotos, ...validFiles]);
		} else {
			setBusinessPhotoError("You can upload only 3 business photographs.");
		}
	};


    const handleSubmit = async () => {

        if (!businessDescription) {
            setBusinessDescriptionError("Business description is required");
        } else {
            setBusinessDescriptionError("");
        }

        if (!businessPhoto) {

            setBusinessVideoError("Business Photo is required");
        } else {
            setBusinessVideoError("");
        }

        if (!review) {

            setReviewError("review is required");
        } else {
            setReviewError("");
        }
        setStatus(true);

        if (businessDescription && review) {
            const formData = new FormData();
            formData.append('city_new', BusinessCityNew);
            formData.append('state_new', BusinessStateNew);
            formData.append('category_new', BusinessCategoryNew);
            formData.append('subcategory_new', BusinessSubCategoryNew);
            formData.append('city_id', BusinessCity);
            formData.append('bus_category_id', BusinessCategory);
            formData.append('bus_subcategory_id', BusinessSubCategory);
            formData.append('state_id', BusinessState);
            formData.append('bus_name', BusinessName);
            formData.append('bus_addr1', bussinessAddress);
            formData.append('bus_addr2', bussinessAddress);
            formData.append('review', review);
            formData.append('bus_phone', BusinessPhone);
            formData.append('wtsapp_phone_mobile', BusinessPhone);
            formData.append('fb_url', Facebook);
            formData.append('web_url', BusinessWebsite);
            formData.append('insta_url', Instagram);
            formData.append('booking_url', Instagram);
            formData.append('location_url', bussinessAddressUrl);
            formData.append('working_days', JSON.stringify(BusinessDay));
            for (let index = 0; index < businessPhoto.length; index++) {
                formData.append('images[]', businessPhoto[index]);
            }

            formData.append('from_time', BusinessFrom);
            formData.append('to_time', BusinessTo);
            formData.append('bus_services', JSON.stringify(serviceInput));
            formData.append('description', businessDescription);
            formData.append('offers', businessDiscount);
            formData.append('youtube', Youtube);

            formData.append('bus_email', bussinessEmail);
            formData.append('added_by', relation);
            formData.append('objective', JSON.stringify(objective));
            formData.append('name', Name);
            formData.append('linkedin_url', Linkedin);
            formData.append('youtube_link', Youtube);
            try {

                let resp = await CommonAPi(Endpoints.ListListingNew, formData);
                if (resp.status == true) {
                    ToastMessage.Success(resp.message);
                    setStatus(false);
                    setActiveTab(1);
                    setBusinessName("");
                    setBusinessPhone("");
                    setServiceProvide("");
                    setBusinessWebsite("");
                    setBusinessCity("");
                    setBusinessState("");
                    setBusinessCategory("");
                    setBusinessSubCategory("");
                    setBusinessCityNew("");
                    setBusinessStateNew("");
                    setBusinessCategoryNew("");
                    setBusinessSubCategoryNew("");
                    setBusinessFrom("");
                    setBusinessTo("");
                    setBusinessDay([]);
                    setReview("");
                    setBusinessDiscount("");
                    setBusinessPhoto([]);
                    setBusinessDescription("");
                    setBusinessVideo("");
                    setServiceInput([""]);
                    setYoutube("");
                    setLinkedin("");
                    setLinkedin("");
                    setFacebook("");
                    setInstagram("");
                    setObjective([]);
                    setbussinessEmail("");
                    setbussinessAddressUrl("");
                    setbussinessAddress("");
                    setRelation("");
                    setName("");
                } else {
                    setStatus(false);
                    if(resp.message?.bus_email){
                        ToastMessage.Error(resp.message?.bus_email[0]);
                    }else{
                        ToastMessage.Error(resp.message);
                    }
                    
                }
            }
            catch (e) {
                setStatus(false);
                if (e && e.response && e.response.data && e.response.data.msg) {
                    ToastMessage.Error(e.response.data.msg);
                }
            }
        }
    };

    const handleObject = (e, element) => {
        if (e.target.checked) {
            let data = {
                name: element,
                value: 1,
            }
            setObjective([...objective, data])
        } else {
            if (objective.filter((item, index) => item.name === element).length > 0) {
                const newData = objective.filter((item, index) => item.name != element)
                setObjective(newData);
            }
        }
    };

    return (
        <>
            <CustomeHeader />
            <section class="px-6 py-16 grid items-center justify-center bg-gradient-3 text-blue">

                {activeTab === 1 ?

                    <img src="images/business/business_info.png" alt='' className="rounded" />
                    : ""}
                {activeTab === 2 ?
                    <img src="images/business/contact.png" alt='' className="rounded" />
                    : ""}
                {activeTab === 3 ?
                    <img src="images/business/object.png" alt='' className="rounded" />
                    : ""}
                {activeTab === 4 ?
                    <img src="images/business/social.png" alt='' className="rounded" />
                    : ""}
                {activeTab === 5 ?
                    <img src="images/business/more.png" alt='' className="rounded" />
                    : ""}

                    <div class="rounded-2xl bg-off-white mt-5 px-2" style={{ display: activeTab === 1 ? 'block' : 'none' }}>
                        <form action="#" className="list-bussiness" ref={setponeref}>
                            <div class="mx-auto container " >
                                <h3 class="mb-4 w-full text-center lg:font-serif mt-3">Tell us about your business</h3>
                                <div class=" ">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label for="name">Business Name <span class="text-red">*</span></label>
                                            <input
                                                class="w-full rounded-md border border-blue p-1 "
                                                type="text"
                                                name="bussinesname"
                                                placeholder="Business Name"
                                                value={BusinessName}
                                                onChange={(e) => {
                                                    setBusinessName(e.target.value)
                                                }}
                                            />
                                            {BusinessNameError ?
                                                <p className="text-danger">
                                                    {BusinessNameError}
                                                </p>
                                                : ""}
                                        </div>
                                        <div className="col-md-6">
                                            <label id="vertical-label" for="vertical-input">What's app Business phone no(eg. 99999 99999)
                                                <span class="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                class="w-full rounded-md border p-1 "
                                                placeholder="What's app Business phone no"
                                                value={BusinessPhone}
                                                onChange={(e) => {
                                                    setBusinessPhone(e.target.value)
                                                }}
                                            />
                                            {BusinessPhoneError ?
                                                <p className="text-danger">
                                                    {BusinessPhoneError}
                                                </p>
                                                : ""}
                                        </div>

                                        <div className="col-md-6">
                                            <label for="service">Services provided<span class="text-red">*</span></label>
                                            <input
                                                class="w-full rounded-md border p-1 "
                                                type="text"
                                                name="service"
                                                value={ServiceProvide}
                                                onChange={(e) => {
                                                    setServiceProvide(e.target.value)
                                                }}
                                                placeholder="Services provided"
                                            />
                                            {ServiceProvideError ?
                                                <p className="text-danger">
                                                    {ServiceProvideError}
                                                </p>
                                                : ""}

                                        </div>
                                        <div className="col-md-6">
                                            <label id="vertical-label" for="vertical-input">Business Websites


                                            </label>

                                            <input
                                                type="text"
                                                class="w-full rounded-md border p-1 "
                                                placeholder="Business Websites"
                                                value={BusinessWebsite}
                                                onChange={(e) => {
                                                    setBusinessWebsite(e.target.value)
                                                }}
                                            />


                                        </div>
                                        <div className="col-md-6">
                                            <label id="vertical-label" for="vertical-input">Bussiness State
                                                <span class="text-red">*</span>
                                            </label>

                                            <select class="w-full rounded-md border p-1 "
                                                onChange={(e) => {
                                                    handleChangeState(e.target.value)

                                                }}
                                                placeholder='Bussiness State'>
                                                <option>Select Business State</option>

                                                {stateData.map((item, index) => {
                                                    return (
                                                        <option key={index} value={index}>
                                                            {item?.name}
                                                        </option>
                                                    )
                                                })}
												<option value="other">Other State If not in the list</option>
                                            </select>
                                           
												<input
                                                        type="text"
                                                        placeholder="Your State"
                                                        class="w-full rounded-md border p-1 "
                                                        value={BusinessStateNew}
                                                        onChange={(e) => {
                                                            setBusinessStateNew(e.target.value)
                                                        }}
														style={{ display: BusinessState == 'other' ? 'block' : 'none' }}
                                                    />
													 {BusinessStateError ?
                                                <p className="text-danger">
                                                    {BusinessStateError}
                                                </p>
                                                : ""}
                                        </div>
                                        <div className="col-md-6">
                                            <label id="vertical-label" for="vertical-input">Business City
                                                <span class="text-red">*</span>
                                            </label>
                                            <select class="w-full rounded-md border p-1 "
                                                onChange={(e) => {
                                                    handleChangeCity(e.target.value)

                                                }}
                                            >
                                                <option>Select Business City</option>
                                                {cityData.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item?.name}>
                                                            {item?.name}
                                                        </option>
                                                    )
                                                })}
												<option value="other">Other City If not in the list</option>
                                            </select>
                                           
												<input
                                                        type="text"
                                                        placeholder="Your City"
                                                        class="w-full rounded-md border p-1 "
                                                        value={BusinessCityNew}
                                                        onChange={(e) => {
                                                            setBusinessCityNew(e.target.value)
                                                        }}
														style={{ display: BusinessCity == 'other' ? 'block' : 'none' }}
                                                    />
													 {BusinessCityError ?
                                                <p className="text-danger">
                                                    {BusinessCityError}
                                                </p>
                                                : ""}
                                        </div>


                                        <div className="col-md-6">
                                            <label>Business Category<span class="text-red">*</span></label>
                                            <select class="w-full rounded-md border p-1 "
                                                onChange={(e) => {
                                                    handleChangeCategory(e.target.value)

                                                }}
                                            >
                                                <option>Select Business Category</option>
                                                {categoryData.map((item, index) => {
                                                    return (
                                                        <option key={index} value={index}>
                                                            {item?.name}
                                                        </option>
                                                    )
                                                })}
												<option value="other">Other Category If not in the list</option>
                                            </select>
                                          
												 <input
                                                        type="text"
                                                        placeholder="Your Category"
                                                        class="w-full rounded-md border p-1 "
                                                        value={BusinessCategoryNew}
                                                        onChange={(e) => {
                                                            setBusinessCategoryNew(e.target.value)
                                                        }}
														style={{ display: BusinessCategory == 'other' ? 'block' : 'none' }}
                                                    />
													  {BusinessCategoryError ?
                                                <p className="text-danger">
                                                    {BusinessCategoryError}
                                                </p>
                                                : ""}
                                        </div>
                                        <div className="col-md-6">
                                            <label id="vertical-label" for="vertical-input">Business Sub Category
                                                <span class="text-red">*</span>
                                            </label>
                                            <select class="w-full rounded-md border p-1 "
                                                onChange={(e) => {

                                                    setBusinessSubCategory(e.target.value)
                                                }}
                                            >
                                                <option>Select Business Sub Category</option>
                                                {subCategoryData.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item?.id}>
                                                            {item?.name}
                                                        </option>
                                                    )
                                                })}
												<option value="other">Other SubCategory If not in the list</option>
                                            </select>
                                            
												 <input
                                                        type="text"
                                                        placeholder="Your Subcategory"
                                                        class="w-full rounded-md border p-1 "
                                                        value={BusinessSubCategoryNew}
                                                        onChange={(e) => {
                                                            setBusinessSubCategoryNew(e.target.value)
                                                        }}
														style={{ display: BusinessSubCategory == 'other' ? 'block' : 'none' }}
                                                    />
													{BusinessSubCategoryError ?
                                                <p className="text-danger">
                                                    {BusinessSubCategoryError}
                                                </p>
                                                : ""}
												
                                        </div>

                                        <div>
                                            <label id="vertical-label" for="vertical-input">Working hours
                                                <span class="text-red">*</span>
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>From</label>
                                                    <input
                                                        type="text"
                                                        class="w-full rounded-md border p-1 "
                                                        value={BusinessFrom}
                                                        onChange={(e) => {
                                                            setBusinessFrom(e.target.value)
                                                        }}
                                                    />
                                                    {BusinessFromError ?
                                                        <p className="text-danger">
                                                            {BusinessFromError}
                                                        </p>
                                                        : ""}
                                                </div>
                                                <div className="col-md-6">
                                                    <label>To</label>
                                                    <input
                                                        type="text"
                                                        class="w-full rounded-md border p-1 "
                                                        value={BusinessTo}
                                                        onChange={(e) => {
                                                            setBusinessTo(e.target.value)
                                                        }}
                                                    />
                                                    {BusinessToError ?
                                                        <p className="text-danger">
                                                            {BusinessToError}
                                                        </p>
                                                        : ""}
                                                </div>

                                            </div>



                                        </div>
                                        <div>
                                            <label id="vertical-label" for="vertical-input">Working days
                                                <span class="text-red">*</span>
                                            </label>

                                            <input type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Monday")
                                                }} />{" "} Monday{" "}
                                            <input
                                                type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Tuesday")
                                                }}
                                            />{" "} Tuesday{" "}
                                            <input
                                                type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Wednesday")
                                                }}
                                            />{" "} Wednesday{" "}
                                            <input
                                                type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Thursday")
                                                }}
                                            />{" "} Thursday{" "}
                                            <input
                                                type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Friday")
                                                }}
                                            />{" "} Friday{" "}
                                            <input
                                                type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Saturday")
                                                }}
                                            />{" "} Saturday{" "}
                                            <input
                                                type="checkbox"
                                                className="p-2"
                                                onChange={(e) => {
                                                    handleCheckBox(e, "Sunday")
                                                }}
                                            />{" "} Sunday{" "}

                                            {BusinessDayError ?
                                                <p className="text-danger">
                                                    {BusinessDayError}
                                                </p>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="relative mx-auto mt-6 grid max-w-xs justify-center gap-3 mt-4 mb-4"><button
                                class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                type="button" data-track="newbiz_start"
                                onClick={() => {
                                    handleNext(2)
                                }}><span class="flex-1 text-center" >Let's get
                                    started!</span></button></div>
                        </form>
                    </div>

                    <div class="rounded-2xl bg-off-white mt-5 px-2" style={{ display: activeTab === 2 ? 'block' : 'none' }}>
                        <form action="#" className="list-bussiness" ref={steptworef}>
                            <div class="mx-auto container ">
                                <h3 class="mb-4 w-full text-center lg:font-serif mt-3">Contact Info</h3>
                                <div class=" ">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label for="company"> Your name <span class="text-red">*</span></label>
                                            <input
                                                class="w-full rounded-md border border-blue p-1 "
                                                type="text"
                                                name="company"
                                                value={Name}
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                }}
                                                placeholder="Your Name"
                                            />
                                            {NameError ?
                                                <p className="text-danger">
                                                    {NameError}
                                                </p>
                                                : ""}
                                        </div>
                                        <div className="col-md-12">
                                            <label id="vertical-label" for="vertical-input">Your relationship with the business
                                                <span class="text-red">*</span>
                                            </label>
                                            {/* <input type="text" class="w-full rounded-md border p-1 " /> */}
                                            <select class="w-full rounded-md border p-1"

                                                onChange={(e) => {
                                                    setRelation(e.target.value)
                                                }}
                                            >
                                                <option class="w-full rounded-md border p-1" >Select your relationship with the business</option>
                                                <option class="w-full rounded-md border p-1" value={"Owner"}>Owner</option>
                                                <option class="w-full rounded-md border p-1" value={"Agency"}>Agency</option>
                                                <option class="w-full rounded-md border p-1" value={"Representative"}>Representative</option>

                                            </select>
                                            {relationError ?
                                                <p className="text-danger">
                                                    {relationError}
                                                </p>
                                                : ""}
                                        </div>

                                        <div className="col-md-12">
                                            <label for="Zip_Code__c">Business address<span class="text-red">*</span></label>
                                            <input
                                                class="w-full rounded-md border p-1 "
                                                type="text"
                                                name="Zip_Code__c"
                                                value={bussinessAddress}
                                                onChange={(e) => {
                                                    setbussinessAddress(e.target.value)
                                                }}
                                                placeholder='Business Address'
                                            />
                                            {bussinessAddressError ?
                                                <p className="text-danger">
                                                    {bussinessAddressError}
                                                </p>
                                                : ""}

                                        </div>
                                        <div className="col-md-12">
                                            <label id="vertical-label" for="vertical-input">Business address URL

                                                <span class="text-red">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                class="w-full rounded-md border p-1 "
                                                value={bussinessAddressUrl}
                                                onChange={(e) => {
                                                    setbussinessAddressUrl(e.target.value)
                                                }}
                                                placeholder='Business address UR'
                                            />
                                            {bussinessAddressUrlError ?
                                                <p className="text-danger">
                                                    {bussinessAddressUrlError}
                                                </p>
                                                : ""}

                                        </div>
                                        <div className="col-md-12">
                                            <label id="vertical-label" for="vertical-input">Business email address
                                                <span class="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                class="w-full rounded-md border p-1 "
                                                value={bussinessEmail}
                                                onChange={(e) => {
                                                    setbussinessEmail(e.target.value)
                                                }}
                                                placeholder='Business email address'
                                            />
                                            {bussinessEmailError ?
                                                <p className="text-danger">
                                                    {bussinessEmailError}
                                                </p>
                                                : ""}
												
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="relative mx-auto mt-6 grid max-w-xs justify-center gap-3 mt-4 mb-4 d-flex">
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button"
                                    onClick={() => {
                                        handleNext(1)
                                    }}
                                ><span class="flex-1 text-center">
                                        Previous</span>
                                </button>
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button"
                                    onClick={() => {
                                        handleNext(3)
                                    }}
                                ><span class="flex-1 text-center">
                                        Next</span>
                                </button>
                            </div>
                        </form>
                    </div>
                  
                    <div class="rounded-2xl bg-off-white mt-5 px-2" style={{ display: activeTab === 3 ? 'block' : 'none' }}>
                        <form action="#" className="list-bussiness" ref={stepthreeref}>
                            <div class="mx-auto container ">
                                <h3 class="mb-4 w-full text-center lg:font-serif mt-3">Your objectives</h3>
                                <div class="text-center">
                                    <div className="row">
                                        <div className="col-md-12 text-center  w-100 d-flex gap-2">
                                            <input
                                                class=" border mt-1"
                                                type="checkbox"
                                                name="company"

                                                onChange={(e) => {
                                                    handleObject(e, "Top 5 Best-Rated Recognition And Placement")
                                                }}
                                                placeholder='Top 5 Best-Rated Recognition And Placement'

                                            />
                                            <h6> Top 5 Best-Rated Recognition And Placement </h6>


                                        </div>
                                        <div className="col-md-12 text-center w-100 d-flex gap-2">

                                            <input
                                                type="checkbox"
                                                class="border mt-1"

                                                onChange={(e) => {
                                                    handleObject(e, "Lead Generation")

                                                }}
                                                placeholder='Lead Generation'
                                            />
                                            <h6>Lead Generation

                                            </h6>

                                        </div>

                                        <div className="col-md-12 text-center w-100 d-flex gap-2">
                                            <input
                                                class=" border mt-1 "
                                                type="checkbox"
                                                name="SocialMediaPromotion"

                                                onChange={(e) => {
                                                    handleObject(e, "Social Media Promotion")
                                                }}
                                                placeholder='Social Media Promotion'

                                            />
                                            <h6>Social Media Promotion</h6>


                                        </div>
                                        <div className="col-md-12 text-center w-100 d-flex gap-2">


                                            <input
                                                type="checkbox"
                                                class=" border mt-1"

                                                onChange={(e) => {
                                                    handleObject(e, "Website Creation Or Revamp")

                                                }}
                                                placeholder='Website Creation or Revamp'

                                            />
                                            <h6>Website Creation or Revamp
                                            </h6>



                                        </div>
                                        <div className="col-md-12 text-center w-100 d-flex gap-2">

                                            <input
                                                type="checkbox"
                                                class=" border mt-1"

                                                onChange={(e) => {
                                                    handleObject(e, "Reputation Management")

                                                }}
                                                placeholder='Reputation Management'

                                            />
                                            <h6>Reputation Management

                                            </h6>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="relative mx-auto mt-6 grid max-w-xs justify-center gap-3 mt-4 mb-4 d-flex">
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button" onClick={() => {
                                        handleNext(2)
                                    }}><span class="flex-1 text-center">
                                        Previous</span>
                                </button>
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button"
                                    onClick={() => {
                                        handleNext(4)
                                    }}><span class="flex-1 text-center">
                                        Next</span>
                                </button>
                            </div>
                        </form>
                    </div>

               
                    <div class="rounded-2xl bg-off-white mt-5 px-2" style={{ display: activeTab === 4 ? 'block' : 'none' }}>
                        <form action="#" className="list-bussiness" ref={stepfourref}>
                            <div class="mx-auto container ">
                                <h3 class="mb-4 w-full text-center lg:font-serif mt-3">Social links</h3>
                                <div class=" ">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label for="company">Instagram </label>
                                            <input
                                                class="w-full rounded-md border border-blue p-1 "
                                                type="text"
                                                value={Instagram}
                                                onChange={(e) => {
                                                    setInstagram(e.target.value)
                                                }}
                                                placeholder='Instagram'
                                            />

                                        </div>
                                        <div className="col-md-12">
                                            <label id="vertical-label" for="vertical-input">Facebook

                                            </label>
                                            <input
                                                type="text"
                                                class="w-full rounded-md border p-1 "
                                                value={Facebook}
                                                onChange={(e) => {
                                                    setFacebook(e.target.value)
                                                }}
                                                placeholder='Facebook'
                                            />

                                        </div>

                                        <div className="col-md-12">
                                            <label for="Zip_Code__c">Linkedin</label>
                                            <input
                                                class="w-full rounded-md border p-1 "
                                                type="text"
                                                value={Linkedin}
                                                onChange={(e) => {
                                                    setLinkedin(e.target.value)
                                                }}
                                                placeholder='Linkedin'
                                            />

                                        </div>
                                        <div className="col-md-12">
                                            <label id="vertical-label" for="vertical-input"> Youtube

                                            </label>

                                            <input
                                                type="text"
                                                class="w-full rounded-md border p-1 "
                                                value={Youtube}
                                                onChange={(e) => {
                                                    setYoutube(e.target.value)
                                                }}
                                                placeholder='Youtube'
                                            />



                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="relative mx-auto mt-6 grid max-w-xs justify-center gap-3 mt-4 mb-4 d-flex">
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button" onClick={() => {
                                        handleNext(3)
                                    }}><span class="flex-1 text-center">
                                        Previous</span>
                                </button>
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button"
                                    onClick={() => {
                                        handleNext(5)
                                    }}
                                ><span class="flex-1 text-center">
                                        Next</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    
                    <div class="rounded-2xl bg-off-white mt-5 px-2" style={{ display: activeTab === 5 ? 'block' : 'none' }}>
                        <form action="#" className="list-bussiness" ref={stepfiveref}>
                            <div class="mx-auto container ">
                                <h3 class="mb-4 w-full text-center lg:font-serif mt-3">More Info</h3>

                                <div className="row">
                                    <div className="col-md-12">
                                        <label for="company">All Services \ products offered By Your Business In Details <span class="text-red">*</span></label>
                                        {serviceInput.map((item, index) => {
                                            return (
                                                <>
                                                    <input
                                                        class="w-full rounded-md border border-blue p-1 "
                                                        type="text"
                                                        onChange={(e) => {
                                                            handleChangeServiceData(e.target.value, index)
                                                        }}
                                                    />

                                                    {index === 0 ?
                                                        <Link onClick={() => {
                                                            setServiceInput([...serviceInput, ""])
                                                        }}>Add More</Link>
                                                        :
                                                        <Link className="text-danger"
                                                            onClick={() => {
                                                                handleRemoveInput(index)
                                                            }}
                                                        >Remove</Link>

                                                    }
                                                </>
                                            )
                                        })}
                                        {serviceError ?
                                            <p className="text-danger">
                                                {serviceError}
                                            </p>
                                            : ""}

                                    </div>
                                    <div className="col-md-12">
                                        <label id="vertical-label" for="vertical-input">MOST IMPORTANT FIELD: Unique description of your business. Written to appeal to potential customers.
                                            (Please do not copy paste from your website)
                                            <span class="text-red">*</span>
                                        </label>
                                        {/* <input type="text"  /> */}
                                        <textarea
                                            rows="30"
                                            cols="30"
                                            class="w-full rounded-md border p-1 "

                                            onChange={(e) => {
                                                setBusinessDescription(e.target.value)
                                            }}
                                        >
                                            {businessDescription}
                                        </textarea>
                                        {businessDescriptionError ?
                                            <p className="text-danger">
                                                {businessDescriptionError}
                                            </p>
                                            : ""}
                                    </div>

                                    <div className="col-md-12">
                                        <label for="Zip_Code__c">Your Business Video YouTube URL (you can also email the video file)</label>
                                        <input
                                            class="w-full rounded-md border p-1 "
                                            type="text"
                                            value={businessVideo}
                                            onChange={(e) => {
                                                setBusinessVideo(e.target.value)
                                            }}

                                        />
                                        {businessVideoError ?
                                            <p className="text-danger">
                                                {businessVideoError}
                                            </p>
                                            : ""}

                                    </div>
                                    <div className="col-md-12">
                                        <label id="vertical-label" for="vertical-input"> Upload your business photographs
                                            <span class="text-red">*</span>
                                        </label>
                                        <input type="file" class="w-full rounded-md border p-1 " multiple
                                            onChange={(e) => {
                                                handleFile(e)
                                            }}
                                        />
										
                                        {businessPhoto.length > 0 ?
                                            <div className="row mt-5">
                                                {businessPhoto.map((item, index) => {
                                                    return (
                                                        <div className="col-md-3" key={index}>
                                                            <img src={URL.createObjectURL(item)} alt='' className="w-75 h-75 object-cover" />
															<p  className="text-danger"><span onClick={() => {
                                                                handleRemoveImage(index)
                                                            }}>Remove Image</span></p>
                                                        </div>
                                                    )
                                                })}

                                            </div>


                                            : ""}
                                        {businessPhotoError ?
                                            <p className="text-danger">
                                                {businessPhotoError}
                                            </p>
                                            : ""}
                                    </div>
                                    <div className="col-md-12">
                                        <label>Review (Do You want people can review your business) <span class="text-red">*</span></label>
                                        <select class="w-full rounded-md border p-1 "
                                            onChange={(e) => {
                                                setReview(e.target.value)
                                            }}
                                        >
                                            <option>Select Review</option>
                                            <option value={"1"}>Yes</option>
                                            <option value={"0"}>No</option>
                                        </select>
                                        {reviewError ?
                                            <p className="text-danger">
                                                {reviewError}
                                            </p>
                                            : ""}

                                    </div>
                                    <div className="col-md-12">
                                        <label id="vertical-label" for="vertical-input"> Special Discounts/<b>Offers</b> for TopFiveBestRated visitors
                                            (we recommended you to give some offer or discount)
                                        </label>
                                        <textarea
                                            rows="30"
                                            cols="30"

                                            onChange={(e) => {
                                                setBusinessDiscount(e.target.value)
                                            }}
                                            class="w-full rounded-md border p-1 ">
                                            {businessDiscount}
                                        </textarea>
                                        {businessDiscountError ?
                                            <p className="text-danger">
                                                {businessDiscountError}
                                            </p>
                                            : ""}
                                    </div>


                                </div>

                            </div>
                            <div class="relative mx-auto mt-6 grid max-w-xs justify-center gap-3 mt-4 mb-4 d-flex">
                                <button
                                    class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                    type="button"
                                    onClick={() => {
                                        handleNext(4)
                                    }}
                                ><span class="flex-1 text-center">
                                        Previous</span>
                                </button>
                                {status ?
                                    <button
                                        class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                        type="button"

                                    >

                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>

                                    :
                                    <button
                                        class="items-center gap-4 rounded-lg font-bold leading-none no-underline lg:leading-none px-5 py-3 text-md lg:text-lg inline-flex bg-orange text-white hover:bg-orange-dark"
                                        type="button"
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    ><span class="flex-1 text-center">
                                            Submit</span>
                                    </button>
                               }
                            </div>
                        </form>
                    </div>
            </section>
            <Footer />
        </>
    )
}
