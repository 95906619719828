import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import { CommonAPi } from "../API/CommonApi";
import ToastMessage from "../Utils/ToastMessage";
import { Link, useNavigate } from 'react-router-dom';
const $ = window.jQuery;
export default function CreateReviewModal({businessId, setBusinessId,createReviewModal}) {
    const [rating, setRating] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const handleRating = (rate) => {
        setRating(rate)
    };

    const handleSave = async(e) =>{
        e.preventDefault();
        let data = {
            business_id:businessId,
            description:description,
            name:name,
            rating:rating
        }
        try {
          
            let resp = await CommonAPi(Endpoints.SaveRating, data);
            if (resp) {
                ToastMessage.Success(resp.message);
                setRating("");
                setDescription("");
                setName("");
                setBusinessId("");
                $("#createReviewModal").modal("hide");
            }else{
                ToastMessage.Error(resp.message); 
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.msg) {
                ToastMessage.Error(e.response.data.msg);
            }
        }

    };
  return (
    <div class="modal fade" id={createReviewModal} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div className="modal-header">
            Write Review & rating 
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-1">
        <div className="container">
          <form onSubmit={handleSave}>
            <div className="row">
                <div className="col-md-12">
                    <label>Name</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        value={name}
                        onChange={(e)=>{
                            setName(e.target.value)
                        }}   
                    />
                </div>
                <div className="col-md-12">
                    <label>Description</label>
                    <textarea className="form-control"
                     onChange={(e)=>{
                         setDescription(e.target.value)
                     }} 
                    >
                    {description}
                    </textarea>
                </div>
                <div className="col-md-12">
                    <label>Rating</label>
                    {rating === null ? 
                    <ReactStars
                        count={5}
                        edit={true}
                        value={rating}
                        size={30}
                        onChange={handleRating}
                        isHalf={false}
                        activeColor="#ffd700"
                        activeBackgroundColor="#ffd700"
                    />
                    :
                    <ReactStars
                        count={5}
                        edit={true}
                        value={rating}
                        size={30}
                        onChange={handleRating}
                        isHalf={false}
                        activeColor="#ffd700"
                        activeBackgroundColor="#ffd700"
                    />
                    }
                    
                </div>
                <div className="col-md-12 mt-3 text-center mb-3">
                    <input type="submit" className="sv-pb " />
                </div>
            </div>
          </form>
        </div>
        </div>
       
      </div>
    </div>
  </div>
  )
}
