import React, { useEffect, useState } from 'react';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
const $ = window.jQuery;

export default function TopListing() {
    const [data, setData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            $(".toplisting-carousel").owlCarousel({
                arrows: true,
                autoplay: true,
                dots: true,
                loop: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    900: {
                        items: 3
                    }
                }
            });

        }, 3000);


    }, [data]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetTopListing);
            if (resp && resp.status === true) {
                let array = [];
                resp?.response.map((item) => {
                    array =  [...array, ...item?.bussiness_data];
                })
                setData( array);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    return (
        <>
        {data.length > 0 ? 
        <section id="toplisting" className="testimonials pb-5 mb-5 mt-5" >
            <div className="container ">

                <div className="section-title text-center" data-aos="zoom-out">
                    <h2>Top Listing</h2>

                </div>

                <div className="owl-carousel toplisting-carousel" data-aos="fade-up">

                    {data.map((item, index) => {
                        return (
                            <div className="testimonial-item sv-dbitemmainwrap" key={index}>
                             <div className="testimonial-thumbnail-box"> <img src={item?.images} alt='' className="bussiness-image"/> </div>
                                <div className="mt-3 text-left p-3"> 
                                    <h3>{item?.name}</h3>
                                    {item?.is_review_show === '1' ? 
                                    <div className="d-flex">
                                            <span class="fa fa-star checked text-warning mr-5"></span>
                                            <span class="fa fa-star checked text-warning mr-5"></span>
                                            <span class="fa fa-star checked text-warning mr-5"></span>
                                            <span class="fa fa-star checked text-warning mr-5"></span>
                                            <span class="fa fa-star checked text-warning mr-5"></span>
                                        </div>
                                        : ""}
                                    <p className='mt-3'>
                                        {item?.address}
                                    </p>
                                    <p className='mt-1'>
                                        {item?.expert_opinion}
                                    </p>
                                </div>
                            </div>
                        )
                    })}






                </div>

            </div>
        </section>
        : ""}
        </>
    )
}
