import React, { useEffect, useState } from 'react';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
const $ = window.jQuery;
export default function Blog() {
    const [data, setData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            $(".testimonials-carousel").owlCarousel({
                arrows:true,
                autoplay: true,
                dots: true,
                loop: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    900: {
                        items: 3
                    }
                }
            });

        }, 2000);


    }, [data]);

    useEffect(() => {
        getBlogData();
    }, [])

    const getBlogData = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetBlog);
            if (resp && resp.status === true) {
                setData(resp?.response);
               
               
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };

    return (
        <>
        {data.length > 0 ? 
         <section id="testimonials" className="testimonials pb-5 mb-5 mt-5">
            <div className="container">

                <div className="section-title text-center" data-aos="zoom-out">
                    <h2>Resources & Research</h2>
                    <p>
                        We've collaborated with professionals across the nation, which has helped us in our
                        research for the selection of the best business. Unlike most content on the web, our
                        resources are written by industry professionals
                    </p>
                </div>

                <div className="owl-carousel testimonials-carousel" data-aos="fade-up">

                    {data.map((item, index) => {
                        return (
                            <div className="testimonial-item sv-dbitemmainwrap " key={index}>

                                <img src={item?.image} alt='' className="w-100" />
                                <div className="mt-3 text-left p-3">
                                    <h4>{item?.heading}</h4>
                                    <h3>{item?.name}</h3>
                                    <p className="text-left">
                                        {item?.description}
                                    </p>
                                </div>

                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
        : ""}
        </>
       
    )
}
