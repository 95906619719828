import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Endpoints } from "../API/Endpoints";

function Footer() {
	  const [whatsappLink, setWhatsAppLink] = useState('');

	  useEffect(() => {
    // Get current page URL, title, and description
    const currentPageUrl = encodeURIComponent(window.location.href);
    const pageTitle = encodeURIComponent(document.title);

    // Construct the WhatsApp link
    const constructedLink =
      'https://wa.me/+918699490011?text=' +
      'Check out this page: ' +
      currentPageUrl +
      '%0A%0A' +
      'Title: ' +
      pageTitle;

    // Set the WhatsApp link in the state
    setWhatsAppLink(constructedLink);
  }, []);

    const handleTop = () =>{
        window.scrollTo(0, 0)
    };
  return (
    <>
    <footer className="sv-mainfooter ">
        <div className="container">
            <div className="row sv-footer">
                <div className="col-lg-6">
                    <div className="sv-footeritem">
                        <figure>
                            <img src="../../images/logo.png" alt="image description" style={{width: "250px"}} />
                        </figure>
                        <div className="sv-main-description px-2">
                            <p>Most Reliable Source To Find Industry Experts.</p>
                            <p>Helpline -  +91-8699490011</p>
                            <p>Email - support@topfivebestrated.com</p>
                        </div>
                       
                       
                       


                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 ">
                    <div className="sv-footerlinks px-2">
                        <div className="sv-foootertittle">
                            <h5>Trending Categories</h5>
                        </div>
                        <ul className="sv-footerlink">
                            <li><Link to="/contact" onClick={handleTop}>Get In Touch</Link></li>
                            {/* <li><Link to="#">Privacy</Link></li> */}
                            <li><Link to="/list-business" onClick={handleTop}>Get Listed</Link></li>
                            {/* <li><Link to="#">address</Link></li> */}

                        </ul>

                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 ">
                    <div className="sv-footerlinks px-2">
                        <div className="sv-foootertittle">
                            <h5>Useful Links</h5>
                        </div>
                        <ul className="sv-footerlink">
                            <li><Link to="/about" onClick={handleTop}>About Us</Link></li>
                            {/* <li><Link to="#">How it works</Link></li>
                             */}
                            <li><Link to={Endpoints.imagebaseUrl + "/login"} target={"_blank"} onClick={handleTop}>Login</Link></li>
                            <li><Link to="/terms-condition" onClick={handleTop}>Term & Condition</Link></li>

                        </ul>

                    </div>
                </div>
            </div>
        </div>
        <div className="sv-footerbtm">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-sm-9">
                        <div className="sv-footerbtmtitle">
                            <h6>© Copyright Top Five Best Rated. All Rights Reserved </h6>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3">
                    <div className="social-links">
                            <a target="_blank" href="https://www.youtube.com/channel/UCgqqJQnyO2k1T3lTAfm0v1g" className="bg-white">
                                {/* <i className="icon-youtube"></i> */}
                                <img src="../../images/social/youtube.png" alt="" className='rounded-circle' />
                            </a>
                            <a target="_blank" href="https://twitter.com/Topfivebest12" className="twitter bg-white"  >
                                <img src="../../images/social/twitter.png" alt="" className=' rounded-circle'/>
                            </a>
                            <a target="_blank" href="https://www.facebook.com/topfivebestrated/" className="facebook bg-white">
                                <img src="../../images/social/facebook.png" alt="" className='rounded-circle'/>
                            </a>
                            <a target="_blank" href="https://www.instagram.com/topfivebestratedindia/?igsh=dDc0Z2VtcnZldGY4&utm_source=qr" className="instagram bg-white">
                                <img src="../../images/social/instagram.png" alt="" className=' rounded-circle'/>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/in/top-five-best-rated-india-05240a298/" className="linkedin bg-white">
                                <img src="../../images/social/linkedin.png" alt="" className='rounded-circle'/>
                            </a>
                    </div>
                      
                    </div>

                </div>
            </div>
        </div>
    </footer>

   
    <div className="right-sidebar">
        <div className="switcher-icon">
        <a target="_blank" href={whatsappLink} className="">
                <img src="../../images/whatsapp.svg" />
            </a>
        </div>
    </div>
    </>
  );
}

export default Footer;
