import React from 'react';
import CustomeHeader from '../Layout/CustomeHeader';
import Footer from '../Layout/Footer';

export default function Faq() {
    return (
        <>
            <CustomeHeader />
            <main className="sv-main sv-content">
                <div className="container container-fluid">
                    <div className="row gy-4">

                        <div className="col-md-12">

                            <div className="sv-faq-acordian">
                                <div className="sv-acoridan_title">
                                    <h3>Frequently Asked Questions</h3>
                                </div>
                                <div className="sv-acordian">
                                    <ul id="sv-accordion" className="sv-accordion-faq">
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" data-bs-toggle="collapse"
                                                role="button" data-bs-target="#collapseLi1" aria-expanded="false">
                                                <h5>1. We make your Search easy</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi1" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>
                                                    We're here to simplify your search. We're among the top five best-rated platforms that provide you with the finest options in your city. Our team conducts thorough research and even arranges mystery shopper phone evaluations for every company. This ensures that we deliver the top results in your area, making it easy for our users to find the best services.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseLi2" aria-expanded="false">
                                                <h5>2. What is the benefit of submitting my business for a listing at Top Five
                                                    Best Rated If there are already 5 top rated businesses?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi2" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>We ask business owners to submit details to evaluate them based on our
                                                        criteria and select the top five in each category. Submission increases
                                                        the pool
                                                        of candidates that will eventually get listed on our website. Your
                                                        submission will
                                                        allow us to evaluate your company and if it is better than the existing
                                                        list then it
                                                        will be updated however, if it does not match the parameters, your
                                                        business will remain
                                                        as a part of our database.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseLi3" aria-expanded="false">
                                                <h5>3. How Does Top Five Best Rated generate income?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi3" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>Just like most non-paid features and services on the internet,
                                                        Top Five Best Rated generates income from ad revenue. We allow
                                                        advertisers to display advertisements on our page towards our traffic
                                                        and this enables us to
                                                        generate the necessary income to perform our services and help you. </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseLi4" aria-expanded="false">
                                                <h5>4. How do you decide what business to feature on the top five best-rated
                                                    pages?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi4" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>As Top Five Best Rated is a non paid for rating website, all listings are
                                                        purely based on a personal evaluation by our team based on several
                                                        factors
                                                        including value for money, effectiveness, reputation, customer feedback
                                                        and so on.
                                                        This provides our users with the most trustworthy and unbiased listings.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseLi5" aria-expanded="false">
                                                <h5>5. How Regular are your visits and evaluation of the businesses? </h5>
                                            </div>
                                            <div className="collapse" id="collapseLi5" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>We do not visit the establishments every day but rather periodically
                                                        so that we can frequently update and modify our lists
                                                        based on changing parameters.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseLi6" aria-expanded="false">
                                                <h5>6. Why does Top Five Best Rated not post any negative feedback/ reviews on
                                                    its page?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi6" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>At Top Five Best rated, our goal is to enable our users to access the
                                                        best-rated services and business establishments and we focus
                                                        specifically on that. We believe by listing harsh or negative reviews,
                                                        we not only do not create any value addition but might also negatively
                                                        impact a business that might already have its issues. We are committed
                                                        to creating an atmosphere of trust and writing berating reviews can
                                                        hamper this trust and defeats the purpose of our website.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi7" aria-expanded="false">
                                                <h5>7. Once listed on the website, how long will it hold its place?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi7" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>Our lists are constantly updated based on changing parameters; however once a
                                                        business is listed on our website, it typically holds its place for about 3
                                                        months before being updated. After a period, your business will be evaluated
                                                        to accommodate any new entries or changes.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi8" aria-expanded="false">
                                                <h5>8. Do the positions of listings mean anything and does the website follow a
                                                    ranking system?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi8" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p> No, the position does not mean anything. All businesses listed are equal and
                                                        we do not follow any sort of ranking system. All the names that are listed
                                                        are equally good for users to choose from. Our website has a feature which
                                                        shuffles all listings to prevent a stagnant assumption of ranking This
                                                        assures our users that we have no ranking.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi9" aria-expanded="false">
                                                <h5>9. Are all discounts listed valid?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi9" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>Businesses and their policy constantly evolve and for that reason, Top Five
                                                        Best rated cannot provide any sort of guarantee that the discounts listed
                                                        are valid. They are subject to change. </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi10" aria-expanded="false">
                                                <h5>10. My business got featured on this list, How can I promote this?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi10" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>
                                                        A listing like this can truly help boost your business and for that reason,
                                                        we encourage all business owners to promote this as much as possible on all
                                                        social media or print marketing geared towards your target audience. Some
                                                        ways by which you can promote your business include:
                                                    </p>
                                                    <p>Creating a post and sharing your listing on your social media pages
                                                        All businesses that are listed will obtain a certificate and trophy which
                                                        you may display on your website or social media.

                                                    </p>
                                                    <p>You may also feature your listing on a business wall to attract customers and
                                                        spread the word.

                                                    </p>
                                                    <p>You can also include a mention of your ranking on your website to showcase to
                                                        all customers and potential customers.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi11" aria-expanded="false">
                                                <h5>11. Why do businesses listed have different bar values if there is no ranking
                                                    system in place?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi11" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>The different bar values are to display to our users the performance of an
                                                        establishment under various criteria and how they have performed with
                                                        regards to our different assessment criteria This enables users to select
                                                        the business based on their desired or preferred criteria.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi12" aria-expanded="false">
                                                <h5>12. What is the Top Five Best Rated's policy on business discrimination?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi12" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>We do not stand for or promote any business that promotes any form of
                                                        discrimination For that reason, we do not promote or endorse any business
                                                        that we are aware of who practice racist, casteist or sexist discrimination.
                                                        We are committed to creating a fair and honest listing of the best
                                                        businesses that are available and accessible to all. We have a Zero
                                                        tolerance policy towards discriminatory businesses.

                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sv-accordion-faq_title collapsed" role="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseLi13" aria-expanded="false">
                                                <h5>13. Top Facts About Best Five Rated- Why Choose Us?</h5>
                                            </div>
                                            <div className="collapse" id="collapseLi13" data-bs-parent="#sv-accordion">
                                                <div className="sv-accordion-faq_info">
                                                    <p>We do not charge money for any of our listings</p>
                                                    <p>We are committed to providing honest and unbiased reviews to our users and to
                                                        promote the spirit of fair competition, we do not charge for listings or
                                                        take money to list companies to cater to the needs of our users. </p>
                                                    <p>We provide listings in cities and towns pan India At Top Five Rated, we
                                                        ensure that we not only cover the listings in top cities but also extend our
                                                        services to all smaller towns and tier two cities to enable our user base to
                                                        truly experience what we have to offer We believe in representation and work
                                                        to cover the entire nation.</p>
                                                    <p>We create our listings on a basis of handpicked parameters </p>
                                                    <p>Our dedicated teams carefully evaluate every business based on our array of
                                                        30 parameters ranging from cost efficacy to customer satisfaction. Our team
                                                        carefully evaluates all the factors before publishing the listings to ensure
                                                        that our users are always satisfied and to prevent any sort of unfairness or
                                                        bias.</p>
                                                    <p>Your search is made easy with just the click of a button</p>
                                                    <p>We understand how difficult it can be to get honest and unbiased results to
                                                        your searches and that is why we at Top Five Best Rated are committed to
                                                        ensuring our users experience a hassle-free experience. </p>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
