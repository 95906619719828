import React, { useEffect, useState, useCallback } from 'react';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import CustomeHeader from '../Layout/CustomeHeader';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Layout/Footer';


export default function FindBusiness() {

    const [cityData, setCityData] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [SearchStatus, setSearchStatus] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getCity();
    }, []);
    const getCity = async () => {
        try {
            let resp = await FetchApi(Endpoints.FindBusiness);
            if (resp && resp.status === true) {
                setCityData(resp?.response);
                setImageUrl(resp?.image_url);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };

    // const handleSearchCity = async(stirng) =>{
    //     setSearchStatus(false);
    //     if(stirng){
          
    //     try {
    //         let resp = await FetchApi(Endpoints.searchCityState+ "/" +stirng);
    //         if (resp && resp.status === true) {
    //             setSearchStatus(true);
    //             setCityData(resp?.response);
    //             setImageUrl(resp?.image_url);
    //         }
    //     }
    //     catch (e) {
    //         if (e && e.response && e.response.data && e.response.data.message) {
    //             ToastMessage.Error(e.response.data.message);
    //         }
    //     }
    // }else{
    //     setSearchStatus(false);
    //     getCity(); 
    // }
    // }

    const handleOnSearchCity = async(stirng) => {
        try {
            let resp = await FetchApi(Endpoints.SearchCity+ "/" +stirng);
            if (resp && resp.status === true) {
                let newData =  resp?.response.map((item)=>{
                    return(
                        {
                        id:item.id,
                        Serchtext:stirng,
                        name:item.name,
                        slug:item.slug,
                        image: item.img === null ?"images/no_image.png" :  Endpoints.imagebaseUrl +"/assets/img/cities/"+ item.img,
                        }
                    )
                })
                setCityOption(newData);
                setImageUrl(resp?.image_url);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };
    const handleOnSelectCity = (item) => {
        navigate('/local-businesses/'+ item[0]?.slug)
    };
    const RenderCity = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example-city"
                defaultInputValue={''}
                key={""}
                onSearch={handleOnSearchCity}
                onChange={handleOnSelectCity}
                options={cityOption}
                placeholder="Search City"
                labelKey="name"
                minLength={1}
                emptyLabel={"Search City"}
                className={"rounded-pill subcategory-search-input "}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id} className="d-flex">
                       <img src={option.image} alt="images" className="rounded-circle city-image-search" />
                       &nbsp; &nbsp; {option.name}
                        {/* <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter> */}
                    </div>

                )}

                useCache={false}
            />
        )
    }, [cityOption])
    const RenderMobileCity = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example-city"
                defaultInputValue={''}
                key={""}
                onSearch={handleOnSearchCity}
                onChange={handleOnSelectCity}
                options={cityOption}
                placeholder="Search City"
                labelKey="name"
                minLength={1}
                emptyLabel={"Search City"}
                className={"rounded-pill w-100 "}
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id} className="d-flex">
                       <img src={option.image} alt="images" className="rounded-circle city-image-search" />
                       &nbsp; &nbsp;{option?.name}
                        {/* <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter> */}
                    </div>

                )}

                useCache={false}
            />
        )
    }, [cityOption])

    return (
        <>
            <CustomeHeader />
            <main className="sv-main sv-content jdcatefilter d-none d-md-block">
                <div className="container">
                    <div className="row gy-4">
                       

                        <div className="col-md-12">

                            <h3> 
                            Find Experts Recommended<b> Top Five Best Rated Businesses</b>, Selected 
                            By Our Own Inspection Criteria Designed By Industry Experts All Over 
                            The World !
                            </h3>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12 catefilter_searchbox">
                          
                            {RenderCity()}
                        </div>

                        <div className="sv-explore-categories d-flex">
                        <div className="row" >
                            {cityData.map((item, index) => {
                                return (
                                    < >
                                        {item.city.map((ite, i) => {

                                            return (
                                                <div className={"col-md-2 col-sm-6 col-6 mb-3"}>
                                                    {i === 0 ?   <h5>{item.name}</h5> :""}
                                                   
                                                    <Link to={"/local-businesses/" + ite.slug}>
                                                        <div className={ SearchStatus === true ? i === 0  ? "sv-explore-content-city mt-4 " : "sv-explore-content-city mt-5 div-margin-left" : i === 0  ? "sv-explore-content-city mt-4 "  : "sv-explore-content-city mt-5"}>
                                                           
                                                                {ite.img === null ?

                                                                    <img src={"../images/no_image.png"} alt="images" className="city-image" />
                                                                    :
                                                                    <img src={Endpoints.imagebaseUrl + "/assets/img/cities/" + ite.img} alt="images" className="city-image" />
                                                                }
                                                            {/* </figure> */}
                                                            <div className="sv-explore-info">
                                                                <h6>{ite.name}</h6>

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </div>
                        </div>
                      
                    </div>
                </div>
            </main>

            <main className="sv-main sv-content jdcatefilter d-md-none d-sm-block">
                <div className="container">
                    <div className="row gy-4">
                       

                        <div className="col-md-12">

                            <h3 style={{ fontSize: "1rem"}}> Find Experts Recommended The Top Five Best Rated Businesses,
                                Selected By Our Own Inspection Criteria Designed By Industry
                                Experts All Over The World !</h3>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12 catefilter_searchbox">
                          
                            {RenderMobileCity()}
                        </div>

                        <div className="sv-explore-categories d-flex">
                        <div className="row" >
                            {cityData.map((item, index) => {
                                return (
                                    < >
                                        {item.city.map((ite, i) => {

                                            return (
                                                <div className={"col-md-2 col-sm-6 col-6 mb-3"}>
                                                    {i === 0 ?   <h5 style={{ font: "799 1rem/1.4444444444em 'Outfit', sans-serif"}}>{item.name}</h5> :""}
                                                   
                                                    <Link to={"/local-businesses/" + ite.slug}>
                                                        <div className={ SearchStatus === true ? i === 0  ? "sv-explore-content-city mt-4 " : "sv-explore-content-city mt-5 div-margin-left" : i === 0  ? "sv-explore-content-city mt-4 "  : "sv-explore-content-city mt-5"}>
                                                           
                                                                {ite.img === null ?

                                                                    <img src={"../images/no_image.png"} alt="images" className="city-image" />
                                                                    :
                                                                    <img src={Endpoints.imagebaseUrl + "/assets/img/cities/" + ite.img} alt="images" className="city-image" />
                                                                }
                                                            {/* </figure> */}
                                                            <div className="sv-explore-info">
                                                                <h6>{ite.name}</h6>

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </div>
                        </div>
                       
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
