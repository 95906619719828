import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Utils/ScrollToTop";
import "./App.css";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Index from "./Pages/Index";
import About from "./Pages/About";
import Faq from "./Pages/Faq";
import FindBusiness from "./Pages/FindBusiness";
import LocalBusiness from "./Pages/LocalBusiness";
import ListBusiness from "./Pages/ListBusiness";
import Bussiness from "./Pages/Bussiness";
import Model from "./Pages/Model";
import Contact from "./Pages/Contact";
import Terms from "./Pages/Terms";
const $ = window.jQuery;


function App() {
  // const showPopModel = localStorage.getItem("showPopModel");
  // useEffect(() => {
   
  //   if (showPopModel == null || showPopModel == false || showPopModel == undefined) {
  //     $("#exampleModal").modal("show");
  //   } else {
  //     $("#Model").modal("hide");
  //   }
  // }, [showPopModel]);

 
  return (
        <Router>
      <ScrollToTop />
      <Routes>
        
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/find-business" element={<FindBusiness />} />
        <Route path="/local-businesses/:slug" element={<LocalBusiness />} />
        <Route path="/local-businesses/:city/:slug" element={<Bussiness />} />
        <Route path="/list-business" element={<ListBusiness />} />
        {/* <Route path="/get-business/:slug" element={<Bussiness />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-condition" element={<Terms />} />
      
      
   
      </Routes>
    </Router>

  );
}

export default App;
