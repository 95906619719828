import React, { useEffect, useState, useCallback } from 'react';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import CustomeHeader from '../Layout/CustomeHeader';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReadMore from './ReadMore';
import LessReadMore from './LessReadMore';
import BusinessCard from './BusinessCard';
import { RotatingLines } from 'react-loader-spinner'
import Footer from '../Layout/Footer';

export default function Bussiness() {
    const [businessData, setBusinessData] = useState([]);
    const [blogData, setBusinessBlogData] = useState([]);
    const [numberShowIndex, setNumberShowIndex] = useState(null);
    const [serviceShowLimit, setServiceShowLimit] = useState(1);
    const [serviceIndex, setServiceIndex] = useState(1);
    const [status, setStatus] = useState(false);
    const param = useParams();

    useEffect(() => {
        getCity();
    }, []);
    const getCity = async () => {
        setStatus(true);
        try {
            let resp = await FetchApi(Endpoints.GetLocalBusiness + "/" + param?.city + "/" + param?.slug);
            if (resp && resp.status === true) {
                setBusinessData(resp?.response);
                setBusinessBlogData(resp?.blogcontent);
                const timer = setTimeout(() => {
                    setStatus(false)
                  }, 1400);
                
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message);
            }
        }
    };



    return (
        <>
        {status ? 
            <div className="container text-center loader" >
                <center>
                
                    <img src='../../images/loader.gif' alt='' />
                </center>
            </div>
            :
            <>
            <CustomeHeader />
            <div className="bg-yellow py-4 text-center remove-hyphens"
            style={{
                textTransform: "capitalize",
                
            }}
            >
                <h2>Top Five Best {param?.slug?.replace(/[^\w\s]/gi, ' ')} In {param?.city?.replace(/[^\w\s]/gi, ' ')} </h2>

            </div>
            <main class="sv-main sv-bg-light " style={{ padding: "10px" }}>

                <div className="px-4">

                    <p className="mt-30">

                        Our expert has recommended the top five best {param?.slug?.replace(/[^\w\s]/gi, ' ')} services in  {param?.city?.replace(/[^\w\s]/gi, ' ')}. All of our {param?.slug} services have
                        undergone our unique inspection criteria, which includes customer reviews, history, complaints, ratings, satisfaction, trust,
                        cost, and their general excellence. We know that You deserve 'The best, not anyone!
                    </p>
                </div>
				

                {businessData.length > 0 ?
                    <div class="row">
                        {businessData.map((item, index) => {
                            return (
                                <div className="col-md-12" key={index}>
                                    <BusinessCard {...{
                                        item: item,
                                            allitem: businessData,
										
                                        onSuccess:getCity,
                                        slug:param?.slug
                                    }} />
                                </div>
                            )
                        })}



                    </div>
                    :

                    <div class="row">
                        <div class="col-md-4 p-2 pb-3">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../images/top5add.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-2 pb-3">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../images/top5add.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-2 pb-3">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../images/top5add.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-2 pb-3">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../images/top5add.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-2 pb-3">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../images/top5add.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                     
                    </div>


                }
				{blogData && (
				<div class="row m-2">
				  <div className="col-md-12 blogData" dangerouslySetInnerHTML={{ __html: blogData }}></div>
				</div>
				)}

            </main>
            <Footer />
            </> }
        </>
    )
}
