import React, { useEffect, useState } from 'react';
import CustomeHeader from '../Layout/CustomeHeader'
import { FetchApi } from "../API/FetchApi";
import { CommonAPi } from "../API/CommonApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import Footer from '../Layout/Footer';
import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    youtubeIcon,
    instagramIcon,
} from "react-share";
export default function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
const [whatsappLink, setWhatsAppLink] = useState('');

	  useEffect(() => {
    // Get current page URL, title, and description
    const currentPageUrl = encodeURIComponent(window.location.href);
    const pageTitle = encodeURIComponent(document.title);

    // Construct the WhatsApp link
    const constructedLink =
      'https://wa.me/+918699490011?text=' +
      'Check out this page: ' +
      currentPageUrl +
      '%0A%0A' +
      'Title: ' +
      pageTitle;

    // Set the WhatsApp link in the state
    setWhatsAppLink(constructedLink);
  }, []);
    const handleSaveContact = async (e) =>{
        e.preventDefault();
        let data = {
            name:name,
            email:email,
            subject:subject,
            message:message
        }
        try {
          
            let resp = await CommonAPi(Endpoints.ContactSave, data);
            if (resp) {
                ToastMessage.Success(resp.msg);
                setName("");
                setEmail("");
                setSubject("");
                setMessage("");
            }else{
                ToastMessage.Error(resp.msg); 
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.msg) {
                ToastMessage.Error(e.response.data.msg);
            }
        }
    };

    return (
        <>
            <CustomeHeader />
            <main className="sv-main sv-content">
                <section className="pb-4">
                    <div class="container">
                        <div className="section-title" data-aos="zoom-out">
                            <h2>Get In Touch</h2>

                        </div>
                        <div class="row justify-content-center mt-2">
                            <div class="col-md-12">
                            <img src="../images/contact.jpeg" alt='' className="w-100" />
                                <div class="wrapper contact-wrapper">
                                    <div class="row no-gutters contact-row">
                                        <div class="col-xl-8 col-lg-7 col-md-6 contact-form-block order-md-last d-flex align-items-stretch">
                                            <div class="contact-wrap w-100 p-md-5 p-4">
                                                <h3 class="mb-4">Get In Touch</h3>
                                                <div id="form-message-warning" class="mb-4"></div>
                                                <div id="form-message-success" class="mb-4">
                                                    Your message was sent, thank you!
                                                </div>
                                                <form class="contactForm" onSubmit={handleSaveContact} method="post">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label class="label" for="name">Full Name</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="name"
                                                                    id="name"
                                                                    placeholder="Name"
                                                                    value={name}
                                                                    onChange={(e) => {
                                                                        setName(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label class="label" for="email">Email Address</label>
                                                                <input
                                                                    type="email"
                                                                    class="form-control"
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Email"
                                                                    value={email}
                                                                    onChange={(e) => {
                                                                        setEmail(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="label" for="subject">Subject</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    name="subject"
                                                                    id="subject"
                                                                    placeholder="Subject"
                                                                    value={subject}
                                                                    onChange={(e) => {
                                                                        setSubject(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="label" for="#">Message</label>
                                                                <textarea
                                                                    name="message"
                                                                    class="form-control"
                                                                    id="message"
                                                                    cols="30"
                                                                    rows="4"
                                                                    placeholder="Message"
                                                                    value={message}
                                                                    onChange={(e) => {
                                                                        setMessage(e.target.value)
                                                                    }}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <input type="submit" value="Send Message" class="btn btn-primary" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                            <div class="col-xl-4 col-lg-5 col-md-6 d-flex contact-info-block align-items-stretch">
                                            <div class="info-wrap bg-primary w-100 p-md-5 p-4">
                                                <h3>Let's Get In Touch</h3>
                                                <p class="mb-4">We're open for any suggestion or just to have a chat</p>
                                                <div class="dbox w-100 d-flex align-items-start">
                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                            
                                                        <div className="w-50">
                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" class="svg-inline--fa fa-location-dot fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path  fill="white" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"></path></svg>
                                                        </div>
                                                    </div>
                                                    <div class="text pl-3 px-2">
                                                        <p><span>Address:</span> Plot No. 128, Industrial Area, Phase-II, Panchkula Haryana India. 134113</p>
                                                    </div>
                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center">
                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <svg class="h-[1.25em] w-[1.25em]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.583 3.323a1.495 1.495 0 0 0-1.777-.87l-3.3.9A1.504 1.504 0 0 0 2.4 4.801C2.4 14.078 9.922 21.6 19.2 21.6c.674 0 1.267-.454 1.447-1.106l.9-3.3a1.495 1.495 0 0 0-.87-1.777l-3.6-1.5a1.495 1.495 0 0 0-1.736.435L13.826 16.2A12.674 12.674 0 0 1 7.8 10.174l1.848-1.51c.514-.42.69-1.126.435-1.737l-1.5-3.6v-.004Z" fill="white"></path></svg>
                                                    </div>
                                                    <div class="text pl-3 px-2">
                                                        <p><span>Phone:</span> <a href="tel:+91-8699490011">+91-8699490011 </a></p>
                                                    </div>
                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center">
                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <svg class="h-[1.25em] w-[1.25em]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.2 4.8a1.8 1.8 0 0 0-1.08 3.24l8.16 6.12a1.204 1.204 0 0 0 1.44 0l8.16-6.12A1.801 1.801 0 0 0 19.8 4.8H4.2ZM2.4 9v7.8c0 1.324 1.076 2.4 2.4 2.4h14.4c1.324 0 2.4-1.076 2.4-2.4V9l-8.16 6.12a2.396 2.396 0 0 1-2.88 0L2.4 9Z" fill="white"></path></svg>
                                                    </div>
                                                    <div class="text pl-3 px-2">
                                                        <p><span>Email:</span> <a href="mailto:support@topfivebestrated.com">support@topfivebestrated.com</a></p>
                                                    </div>
                                                </div>
                                                {/* <h6 className="text-white">Social :</h6> */}
                                                <div className="d-flex px-2 social-link">
                                                
                                               
                                              
                                                <div class="dbox w-100 d-flex align-items-center mx-1">

                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" href="https://www.youtube.com/channel/UCgqqJQnyO2k1T3lTAfm0v1g" class="">
                                                        <img src="../images/social/youtube.png" alt="" className='w-100 p-2 rounded-circle' />

                                                        </a>
                                                    </div>
                                                   

                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center mx-1">

                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" href={whatsappLink} class=""><img src="../images/whatsapp.svg" className='w-100 p-2 rounded-circle' /></a>
                                                    </div>
                                                   

                                                </div> 
												<div class="dbox w-100 d-flex align-items-center mx-1">

                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" href="https://twitter.com/Topfivebest12" class=""><img src="../images/twitter.svg" className='w-100 p-2 rounded-circle' /></a>
                                                    </div>
                                                   

                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center mx-1">

                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" className="text-white" href="https://www.facebook.com/topfivebestrated/">
                                                          
                                                        <img src="../images/social/facebook.png" alt="" className='w-100 p-2 rounded-circle' />
                                                    </a>
                                                    </div>
                 
                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center mx-1">

                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" className="text-white" href="https://www.instagram.com/topfivebestratedindia/?igsh=dDc0Z2VtcnZldGY4&utm_source=qr">
                                                      
                                                    <img src="../images/social/instagram.png" alt="" className='w-100 p-2 rounded-circle' />
                                                        </a>
                                                    </div>
                                                   

                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center mx-1">

                                                    <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" href="https://www.linkedin.com/in/top-five-best-rated-india-05240a298/" class="linkedin">
                                                        <img src="../images/social/linkedin.png" alt="" className='w-100 p-2 rounded-circle' />
                                                    </a>
                                                    </div>
                                                   

                                                </div>
                                                <div class="dbox w-100 d-flex align-items-center">

                                                    {/* <div class="icon d-flex align-items-center justify-content-center">
                                                    <a target="_blank" href="https://www.linkedin.com/in/topfive-bestrated-410055218/" class="linkedin">
                                                        <img src="../images/social/linkedin.png" alt="" className='w-100 p-2' />
                                                    </a>
                                                    </div> */}
                                                   

                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>

    )
}
